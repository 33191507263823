"use strict";

import { Ajax } from './ajax.js';
import { Dom } from './dom.js';
import { Debug } from './debug.js';
import { PFHBaseClass } from './pfhBaseClass.js';
import { CompteSalarieEditerFichePrestation } from './compteSalarieEditerFichePrestation.js';

/* Section pour virer les warnings de netbeans concernant les variables globales*/
/* global toastr */
/* global CATEGORIE_SALARIALE*/

class CompteSalarie extends PFHBaseClass {
	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//
	
	constructor() {
		super(); // Appelle le constructeur de PFHBaseClass
		
		// Configuration des différents sélécteurs
		this.m_sel = {
			// Formulaire mon compte
			formSalarieNouveauCompte: '#form-salarie-nouveau-compte', // Formulaire de creation d'un nouveau compte
			compteCodePostal: '#code_postal',
			compteExplicatifNumCongeSpectacle: '#form-conges-spectacle-explicatif',
			compteNumCs: '#num_cs',
			compteCbPasDeCongesSpectacles: '#pas-de-conges-spectacle',
			compteCbPasDeVisiteMedicale: '#pas-de-visite-medicale',
			
			// Listing de dossier
			ldLeFltrDossierNom: '#form-recherche-dossier-nom', // Recherche de dossier filtre num dossier
			ldSelFltrDossierStatut: '#form-recherche-dossier-statut', // Recherche de dossier filtre statut
			ldDeFltrDossierDate: '#form-recherche-dossier-date', // Recherche de dossier filtre date
			//ldDpFltrDossierDate: '.datepicker-dossier', // Recherche de dossier filtre Datepicker date
			ldImgFltrDossierNomLoupe: '#form-recherche-dossier-nom-loupe',
			ldBtnEnvoyerMessage: '[name="btn-dossier-envoyer-message"]', // Bouton d'envoi de message pour un dossier : Liste d'objets Dynamique
			
			formSalarieModifierCollegue: '#form-salarie-modifier-collegue',
			
			fltrNomSalarie: '#input-search-salaries-compte-salarie',
			tableResultSalarie: '#table-results-salaries',
			
			bpAjoutIntermittent: '#btn-ajout-intermittent',
			leNumSecu: '#num_secu',
			divMsgAjouterIntermittent: '#ajout-intermittent',
			
			deFltrDateDebut: '#form-recherche-paie-date-begin',
			dpFltrDateDebut: '.datepicker-paies-begin',
			deFltrDateFin: '#form-recherche-paie-date-end',
			dpFltrDateFin: '.datepicker-paies-end',
			selFltrDateVirement: '#form-recherche-paie-date-virement',
			
			formModifierSociete: '#form-salarie-modifier-societe',
			leFilterSociete: "#input-filter-societes",
			btnSupprimerSociete: '[name="btn-societe-supprimer"]',
			
			// Gestiond es fiches de prestation
			bpAjouterFichePresta: '#bouton-salarie-ajouter-fiche-prestation',
			bpEditerFichePresta: '[name="btn-prestation-editer"]',
			btnSupprimerFichePresta: '[name="btn-prestation-supprimer"]',
			
			cdgBtnAjouterDateTravaileeExterne: 'button[name="addDateTravailleeExterne"]',
			cdgSelModeCalculettePoleEmploi: '#modeCalculettePoleEmploi',
			cdgSelModeCalculetteDateOption: '#modeCalculetteInclureDateOption',
			cdgFormValiderDateExamenAnticipe: '#form-valider-date-examen-anticipe',
			cdgProgression: '#calculetteDeclaGouvProgression',
			cdgActivite: '#calculetteDeclaGouvActivite',
			
			//FRANCE TRAVAIL :Sauvegarde d'une date externe
			fenetreSauvegardeDateExterne: '#modal-sauvegarde-date-externe', // Fenetre modale de sauvegarde d'une date externe
			formDateExterne: '#form-sauvegarde-date-externe', // Formulaire de saisie d'une date externe
			fdeAnnee: '#form-sauvegarde-date-externe [name="annee_activite"]',
			fdeCategorieSalariale: '#form-sauvegarde-date-externe [name="categorie_salariale_activite"]',
			fdeIdDateTravaillee: '#form-sauvegarde-date-externe [name="crpt_id_date_travaillee_externe"]',
			fdeEmployeur: '#form-sauvegarde-date-externe [name="employeur_activite"]',
			fdeJourDebut: '#form-sauvegarde-date-externe [name="jour_debut_activite"]',
			fdeJourFin: '#form-sauvegarde-date-externe [name="jour_fin_activite"]',
			fdeMois: '#form-sauvegarde-date-externe [name="mois_activite"]',
			fdeNbCachets: '#form-sauvegarde-date-externe [name="nb_cachets_activite"]',
			fdeNbHeures: '#form-sauvegarde-date-externe [name="nb_heures_activite"]',
			fdeNbJours: '#form-sauvegarde-date-externe [name="nb_jours_activite"]',
			fdeSalaireBrut: '#form-sauvegarde-date-externe [name="salaire_brut_activite"]'
		};
		
		const pageCourante = Dom.getPageName();
		
		switch (pageCourante) {
			//--------------------------------------//
			//-- SECTION MON COMPTE / INSCRIPTION --//
			//--------------------------------------//

			//-- PAGE NOUVEAU COMPTE SALARIE --//
			case 'salarie-inscription': this.initPageInscriptionSalarie(); break;
				
			//-- PAGE MODIFIER COMPTE --//
			case 'salarie-modifier-compte': this.initPageModifierCompteSalarie(); break;
				
			//--------------------------//
			//-- SECTION MES DOSSIERS --//
			//--------------------------//
	
			//-- PAGE LISTER DOSSIER --//
			case 'salarie-lister-dossiers':
			case 'salarie-lister-dossiers-archives': this.initPageListerDossier(); break;
		
			//-------------------------------//
			//-- SECTION MES INTERMITTENTS --//
			//-------------------------------//

			//-- PAGE MODIFIER INTERMITTENTS --//
			case 'salarie-modifier-collegue' : this.initPageModifierCollegue(); break;

			//-- PAGE LISTER INTERMITTENTS --//
			case 'salarie-lister-intermittents' : this.initPageListingCollegues(); break;

			//-- PAGE AJOUTER INTERMITTENT --//	
			case 'salarie-ajouter-collegue': this.initPageAjouterCollegue(); break;

			//-----------------------//
			//-- SECTION MES PAIES --//
			//-----------------------//

			//-- PAGE MES PAIES --//
			case 'salarie-lister-paies' : this.initPageListerPaies(); break;

			//------------------------//
			//-- SECTION MES LISTES --//
			//------------------------//

			//-- PAGE LISTER MES SOCIETES --//
			case 'salarie-lister-societes' : this.initPageListerSocietes(); break;
			
			//-- PAGE MODIFIER UNE SOCIETE --//
			case 'salarie-modifier-societe' : this.initPageModifierSociete(); break;

			//------------------------//
			//-- SECTION MES FICHES --//
			//------------------------//

			//-- PAGE MODIFIER FICHE --//
			case 'salarie-modifier-fiche': this.initPageModifierFiche(); break;

			//-- PAGE LISTER FICHES PRESTATIONS --//
			case 'salarie-lister-prestations' : this.initPageListerPrestations(); break;

			//--------------------------------//
			//-- SECTION MON FRANCE TRAVAIL --//
			//--------------------------------//

			//-- PAGE MES OUTILS POUR FRANCE TRAVAIL --//
			case 'salarie-outils-pole-emploi' : this.initPageOutilsFT(); break;
		}
	}
	
	//---------------------//
	//-- INITIALISATIONS --//
	//---------------------//
	
	initPageInscriptionSalarie() {

		/** 
		 * Formulaire d'inscription d'un salarié 
		 */
		Dom.initFormValidation(
			this.I('formSalarieNouveauCompte'),
			'AppSalarie/inscriptionNouveauCompte', {
				civilite: { required: true },
				nom: { required: true },
				prenom: { required: true },
				rue1: { required: true },
				code_postal: { required: true, checkValidCodePostal: true },
				ville: { required: true },
				id_pays: { required: true },
				telephone: { number: true, telephone :true, required: true },
				email: { required: true, valid_email: true },
				id_pays_naissance: { required: true },
				date_naissance: { required: true, dateBR: true },
				dept_naissance: { required: true },
				lieu_naissance: { required: true },
				id_pays_nationalite: { required: true },
				num_secu: { required: true, num_secu: true },
				num_cs: { required: true, num_cs: true },
				identifiant: { required: true },
				mot_de_passe: { required: true },
				employeur_prod: { atLeastOneChecked: ".cg-employeur" },
				employeur_audio: { atLeastOneChecked: ".cg-employeur" }
			},
			
			{
				employeur_prod: { atLeastOneChecked: "Vous devez choisir au moins un secteur de travail" },
				employeur_audio: { atLeastOneChecked: "Vous devez choisir au moins un secteur de travail" }
			},
			
			{
				employeur_prod: "#error_employeur",
				employeur_audio: "#error_employeur"
			});	

		/** 
		 * Configuration du selecteur de profession 
		 */
		$('#form-salarie-nouveau-compte select[name="profession"]').select2({
			placeholder: "Sélectionnez la profession",
			maximumSelectionLength: 1
		});

		/** 
		 * on modifie le type d'employeur 
		 */
		$('#form-salarie-nouveau-compte #employeur_prod').on('change', () => {
			$('#employeur_audio').valid(); // Forcer la révalidation du champ des autres employeurs
		});

		/** 
		 * on modifie le type d'employeur 
		 */
		$('#form-salarie-nouveau-compte #employeur_audio').on('change', () => {
			$('#employeur_prod').valid(); // Forcer la révalidation du champ des autres employeurs
		});

		/** 
		 * INSCRIPTION ONLY : on modifie le pays 
		 */
		$('#form-salarie-nouveau-compte select[name="id_pays"]').on('change', () => {
			// Forcer la révalidation du champ de code postal lorsque le pays change
			this.I('inscriptionCodePostal').valid();
		});

		/**
		 * On clique sur l'icone pas de visite médicale 
		 */
		this.I('compteCbPasDeVisiteMedicale').click(() => {
			const $explicatifVisiteMedicale = $('#form-visite-medicale-explicatif');
			$explicatifVisiteMedicale.toggle(1000);
		});

		/** 
		 * On clique sur l'icone pas de congés spectacles 
		 */
		this.I('compteCbPasDeCongesSpectacles').click(() => {
			this.I('compteExplicatifNumCongeSpectacle').toggle(1000);

			if(this.I('compteCbPasDeCongesSpectacles').is(":checked")) {
				this.I('compteNumCs').val('A000000');
			}
			else {
				this.I('compteNumCs').val('');
			}
		});
	}

	initPageModifierCompteSalarie() {

		// Initialisation des sélécteurs de la page
		const $selTypeOuvertureDroit = $('#form-modifier-profil-pole-emploi [name="type_ouverture_droit"]');
		const formModifierCompteSalarie = $('#form-modifier-compte-salarie');
		const formModifierProfilPoleEmploi = $('#form-modifier-profil-pole-emploi');

		/** 
		 * On clique sur l'icone pas de congés spectacles 
		 * @TODO DUPLICATION INSCRIPTION
		 */
		$('#form-modifier-compte-salarie .pas-de-conges-spectacle').click(() => {
			this.I('compteExplicatifNumCongeSpectacle').toggle(1000);

			if(this.I('compteCbPasDeCongesSpectacles').is(":checked")) {
				this.I('compteNumCs').val('A000000');
			}
			else {
				this.I('compteNumCs').val('');
			}
		});

		/**
		 * Formulaire de modification d'un compte salarié
		 */
		Dom.initFormValidation(
			formModifierCompteSalarie, 
			'AppSalarie/modifierCompteSalarie', 		
			{
				identifiant: { required: true },
				telephone: { required: true, number: true },
				email: { required: true, valid_email: true },
				rue1: { required: true, alphanumeric: true },
				rue2: { alphanumeric: true },
				code_postal: { required: true, checkValidCodePostal: true },
				ville: { required: true },
				id_pays: { required: true, number: true },
				iban: { iban: true },
				num_cs: { required: true, num_cs : true }
			},
			{
				identifiant: { required: "Merci de renseigner votre identifiant" },
				telephone: { required: "Merci de renseigner votre téléphone", number: "Merci de renseigner votre téléphone avec des caractères numeriques uniquement"  },
				email: { required: "Merci de renseigner votre email", valid_email: "Merci de renseigner un email valide" },
				rue1: { required: "Merci de renseigner votre adresse", alphanumeric: "Merci saisir l'adresse avec des caractères alphanumériques" },
				rue2: { alphanumeric: "Merci saisir l'adresse avec des caractères alphanumériques" },
				code_postal: { required: "Merci de renseigner votre code postal"},
				ville: { required: "Merci de renseigner votre ville" },
				id_pays: { required: "Merci de renseigner votre pays", number: "Merci de renseigner votre ID pays en format numerique"  }
			});

		/**
		 * Formulaire de modification d'un profil pôle emploi 
		 */
		Dom.initFormValidation(
			formModifierProfilPoleEmploi,
			'AppSalarie/modifierProfilPoleEmploi', 
			{
				indemnisation_pole_emploi: { required: true, number: true },
				categorie_salariale: { required: true, number: true },
				type_ouverture_droit: { required: true, number: true },
				allocation_nette: { number: true },
				date_debut_indemnisation: { required: false},
				date_fin_contrat_travail: { required: false},
				salaire_de_reference: { number: true },
				nb_heures: { number: true },
				franchise_salaires: { number: true },
				franchise_conges: { number: true },
				franchise_specifique: { number: true }
			},
			{});

		/** 
		 * Si on modifie le type d'ouverture de droit dans le formulaire pole emploi 
		 */
		$selTypeOuvertureDroit.change(function()
		{
			let disabled = false;

			switch (parseInt($selTypeOuvertureDroit.val()))
			{
				case 2 : { disabled = true; break; }
			}

			let allocNette = $('#form-modifier-profil-pole-emploi [name="allocation_nette"]');
			let dateDebutIndemn = $('#form-modifier-profil-pole-emploi [name="date_debut_indemnisation"]');
			let dateFCT = $('#form-modifier-profil-pole-emploi [name="date_fin_contrat_travail"]');
			let salaireRef = $('#form-modifier-profil-pole-emploi [name="salaire_de_reference"]');
			let nbHeures = $('#form-modifier-profil-pole-emploi [name="nb_heures"]');
			let delai7j = $('#form-modifier-profil-pole-emploi [name="delai_attente_7_jours"]');
			let franSalaires = $('#form-modifier-profil-pole-emploi [name="franchise_salaires"]');
			let franConges = $('#form-modifier-profil-pole-emploi [name="franchise_conges"]');
			let franSpec = $('#form-modifier-profil-pole-emploi [name="franchise_specifique"]');

			allocNette.prop("disabled", disabled);
			dateDebutIndemn.prop("disabled", disabled);
			dateFCT.prop("disabled", disabled);
			salaireRef.prop("disabled", disabled);
			nbHeures.prop("disabled", disabled);
			delai7j.prop("disabled", disabled);
			franSalaires.prop("disabled", disabled);
			franConges.prop("disabled", disabled);
			franSpec.prop("disabled", disabled);

			if (disabled)
			{
				allocNette.val('0');
				dateDebutIndemn.val('');
				dateFCT.val('');
				salaireRef.val('0');
				nbHeures.val('0');
				delai7j.prop("checked", false);
				franSalaires.val('0');
				franConges.val('0');
				franSpec.val('0');
			}		
		});

		$selTypeOuvertureDroit.trigger('change'); // On execute la fonction pour afficher correctement les éléments
	}

	initPageListerDossier() {

		/** 
		 * Clique sur la loupe du filtre par nom d'organisateur ou numero de dossier 
		 * @param {Event} p_event Evenement associé
		 */
		this.I('ldLeFltrDossierNom').keyup((p_event) => {
			//Récupère les evenements clavier du nom de dossier pour valider le retour à la ligne
			if (p_event && p_event.keyCode === 13) {
				this.onMesDossiersFiltersChanged();	
			}		
		});

		/**
		 * Clique sur la loupe du filtre par nom d'organisateur ou numero de dossier 
		 * @param {Event} p_event Evenement associé
		 */
		this.I('ldImgFltrDossierNomLoupe').click((p_event) => this.onMesDossiersFiltersChanged(p_event));

		/** 
		 * Changement de statut sur le filtre de dossiers
		 * @param {Event} p_event Evenement associé
		 */
		this.I('ldSelFltrDossierStatut').change((p_event) => this.onMesDossiersFiltersChanged(p_event));

		/**
		 * Configuration du datePicker du filtre par date 
		 */
		this.I('ldDeFltrDossierDate').datetimepicker({locale: 'fr', format: 'DD/MM/YYYY', useCurrent: false});
		this.I('ldDeFltrDossierDate').on("dp.change", (p_event) => this.onMesDossiersFiltersChanged(p_event));

		/**
		 * Gestion des click sur les éléments dynamiques
		 * @param {Event} p_event Evenement associé
		 */
		$(document).on('click', this.S('ldBtnEnvoyerMessage'), (p_event) => {
			const name = $(p_event.currentTarget).attr('name');
			switch (name) {
				case 'btn-dossier-envoyer-message' :
					this.onEnvoyerMessageDossier(p_event);
					break;
			}			
		});
	}
	
	initPageModifierCollegue() {
		/**
		 *  Formulaire de modification d'un collègue 
		 */
		Dom.initFormValidation(
			this.I('formSalarieModifierCollegue'), 
			'AppSalarie/modifierCollegueSalarie',
			{},
			{});
	}
	
	initPageListingCollegues() {
		// Initialisation des sélécteurs de la page			
		//const $fltrNomSalarie = $('#input-search-salaries-compte-salarie');
		//const $tableResultSalarie = $('#table-results-salaries');

		/** 
		 * Changement de l'ordre des salaries dans le listing des salariés 
		 * @param {Event} p_event Evenement associé
		 */
		$(document).on('click', '[data-ordering-salaries=true]', function (p_event){	
			p_event.preventDefault(); // On désactive l'action par défaut du click

			ordering = $(this).attr('data-ordering');		

			Ajax.postAsync(
				'AppSalarie/orderingSalaries', {
					ordering: ordering
				},

				(p_result) => {				
					// On remplace le tableau de résultat
					$('#table-results-salaries').html(p_result.html);
				},

				(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
			);
		});

		/** 
		 * On tape un nom dans la recherche d'intermittent 
		 */
		this.I('fltrNomSalarie').keyup(() => {
			Ajax.postAsync (
				'AppSalarie/rechercheCollegue', {
					search: this.I('fltrNomSalarie').val()
				},
				
				(p_result) => {
					// On remplace le tableau de résultat
					this.I('tableResultSalarie').html(p_result.html);	
				},
				
				(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
			);
		});
	}
		
	initPageAjouterCollegue() {

		/**
		 * Recherche un intermittent via son numero de securité sociale
		 * @param {Event} p_event Evenement associé
		 * @returns {undefined}
		 */
		this.I('bpAjoutIntermittent').click((p_event) => {
			p_event.preventDefault();

			let numSecu = this.I('leNumSecu').val();

			// Remplacement des départements de Corse
			numSecu = numSecu.replace(/2A|2B/g, match => (match === '2A' ? '19' : '18'));

			// Ajout de la clé du numéro de sécurité sociale si la longueur est de 13 caractères
			if (numSecu.length === 13) {
				let cle = 97 - (numSecu % 97);
				numSecu = numSecu + cle;
			}

			if (numSecu.length === 15) {
				Ajax.postAsync(
					'AppSalarie/listingSalarieAjouter', {
						numSecu: numSecu
					}, 
					
					(p_result) => {
						this.I('divMsgAjouterIntermittent').html(p_result.html).show();
					},
					
					(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
				);
			}
			else {
				toastr.error('Le numéro de sécurité doit contenir 13 ou 15 caractères. Contactez-nous pour un salarié étranger.');
			}
		});
	}
	
	initPageListerPaies() {
		/** Initialisation des Date Picker Mes Paies => Date debut et DateFin*/
		this.I('dpFltrDateDebut').add(this.I('dpFltrDateFin')).datetimepicker({locale: 'fr', format: 'DD/MM/YYYY', useCurrent  : false});

		/** 
		 * Changement des dates sur le filtre paies 
		 * @param {Event} p_event Evenement associé
		 */
		this.I('deFltrDateDebut').add(this.I('deFltrDateFin')).on("dp.change", (p_event) => this.onMesPaiesFiltersChanged(p_event));
		this.I('selFltrDateVirement').change(this.onMesPaiesFiltersChanged);
	}
	
	initPageListerSocietes() {
		/** 
		 * On clique sur le changement d'ordre des sociétés dans le listing de sociétés 
		 * Délégation de l'évenement via "$(document).on()" afin de gérer les ajouts dynamiques
		 * @param {Event} p_event Evenement associé
		 */
		$(document).on('click', '[data-ordering-societes=true]', (p_event) => {
			this.onMesSocietesFiltersChanged(p_event, $(p_event.currentTarget).attr('data-ordering'));
		});

		/** 
		 * On filtre le résultat de la recherche des sociétés en fonction du nom de la société
		 * @param {Event} p_event Evenement associé
		 */
		this.I('leFilterSociete').keyup((p_event) => this.onMesSocietesFiltersChanged(p_event));
		
		/** 
		 * On clique sur le bouton supprimer une fiche
		 * @param {Event} p_event
		 */
		this.I('btnSupprimerSociete').click((p_event) => {			
			p_event.preventDefault();
			if (!confirm("Etes-vous sûr de vouloir supprimer cette société ?")) {
				return;
			}

			const $row = $(p_event.currentTarget).closest('tr'); // La ligne <tr> contenant le bouton "Supprimer"
			const crptIdCompteClient = $row.attr('data-id-compte-client');

			$.blockUI();
			Ajax.postAsync(
				'AppSalarie/societeSupprimer', {
					crptIdCompteClient: crptIdCompteClient
				},

				(p_result) => {
					$row.remove();
					Ajax.defaultAsyncJSONSuccessCallback(p_result, UNBLOCK_UI);
				},

				(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI)
			);
		});			
	}
	
	initPageModifierSociete() {
		/** 
		 * Initialisation du formulaire de modification d'une societe 
		 */
		Dom.initFormValidation(
			this.I('formModifierSociete'),
			'AppSalarie/modifierSociete',
			{},
			{});		
	}
	
	initPageModifierFiche() {
		const editerFichePrestation = new CompteSalarieEditerFichePrestation();
	}
		
	initPageListerPrestations() {

		/** 
		 * On clique sur ajouter une fiche de prestation 
		 */
		this.I('bpAjouterFichePresta').click(() => {
			Ajax.postAsync(
				'AppSalarie/compteInitialiserFichePrestation',
				{},

				(p_event) => Ajax.defaultAsyncJSONSuccessCallback(p_event),

				(p_event) => Ajax.defaultAsyncJSONErrorCallback(p_event)
			);
		});

		/** 
		 * On clique sur le bouton editer
		 * @param {Event} p_event Evenement associé
		 */
		this.I('bpEditerFichePresta').click((p_event) => {
			p_event.preventDefault();
			const $row = $(p_event.currentTarget).closest('tr'); // La ligne <tr> contenant le bouton "Supprimer"
			const crptIdPrestation = $row.attr('data-id-prestation');
			const etat = $row.attr('data-etat');
			
			if (etat !== '0') {
				const answer = confirm("Souhaitez vous repasser cette fiche en brouillon ?");
				if (!answer) {
					return;
				}
			}

			Ajax.postAsync(
				'AppSalarie/fichePrestationEditer', {
					crptIdPrestation: crptIdPrestation
				},

				(p_event) => Ajax.defaultAsyncJSONSuccessCallback(p_event),

				(p_event) => Ajax.defaultAsyncJSONErrorCallback(p_event)
			);
		});

		/** 
		 * On clique sur le bouton supprimer une fiche
		 * @param {Event} p_event
		 */
		this.I('btnSupprimerFichePresta').click((p_event) => {			
			p_event.preventDefault();
			if (!confirm("Etes-vous sûr de vouloir supprimer cette fiche de prestation?")) return;

			const $row = $(p_event.currentTarget).closest('tr'); // La ligne <tr> contenant le bouton "Supprimer"
			const crptIdPrestation = $row.attr('data-id-prestation');

			$.blockUI();
			Ajax.postAsync(
				'AppSalarie/fichePrestationSupprimer', {
					crptIdPrestation: crptIdPrestation
				},

				(p_result) => {
					$row.remove();
					Ajax.defaultAsyncJSONSuccessCallback(p_result, UNBLOCK_UI);
				},

				(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI)
			);
		});			
	}
	
	initPageOutilsFT() {
		
		// Si le calcul d'examen anticipé est actif
		if (this.itemDefined('cdgFormValiderDateExamenAnticipe')) {
			/**
			 * Configuration du formulaire de date d'examen anticipe
			 */
			Dom.initFormValidation(
				this.I('cdgFormValiderDateExamenAnticipe'),
				'AppCompteSalarie/poleEmploiValiderDateExamenAnticipe',
				{ date_examen_anticipe: { required: true, dateBR: true } }, {});
		}
			
		/**
		 * Si on change le mode de la calculette pôle emploi :
		 * on affiche uniquement la calculette correspondant au mode de calcul selectionné par le select
		 * @param {Event} p_event Evenement associé
		 */
		this.I('cdgSelModeCalculettePoleEmploi').change((p_event) => {
			const modeCalcul = $(p_event.currentTarget).val();

			$.blockUI();
			Ajax.postAsync(
				'AppCompteSalarie/calcDeclaGouvValiderDateExamenAnticipe', {
					modeCalcul: modeCalcul
				},

				(p_result) => {
					this.I('cdgProgression').html(p_result.tplProgression);
					this.I('cdgActivite').html(p_result.tplActivite);
					Ajax.defaultAsyncJSONSuccessCallback(p_result, UNBLOCK_UI);
				},

				(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI)
			);
		});

		/**
		 * Si on change le mode de la calculette pôle emploi :
		 * on affiche uniquement la calculette correspondant au mode de calcul selectionné par le select
		 * @param {Event} p_event Evenement associé
		 */
		this.I('cdgSelModeCalculetteDateOption').change((p_event) => {
			const enableDateOption = $(p_event.currentTarget).val();

			$.blockUI();
			Ajax.postAsync(
				'AppCompteSalarie/calcDeclaGouvMajDateOption', {
					enableDateOption: enableDateOption
				},

				(p_result) => {
					this.I('cdgProgression').html(p_result.tplProgression);
					this.I('cdgActivite').html(p_result.tplActivite);
					Ajax.defaultAsyncJSONSuccessCallback(p_result, UNBLOCK_UI);
				},

				(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI)
			);
		});
		
		/**
		 * Formulaire d'ajout d'une activitée externe dans Mon Pôle-Emploi
		 */	
		Dom.initFormValidation(
			this.I('formDateExterne'),
			"AppCompteSalarie/calcDeclaGouvSauvegarderDateExterne", 
			{
				employeur_activite: { required: true },
				annee_activite: { required: true, number: true, min: 2000 },
				mois_activite: { required: true, number: true },
				jour_debut_activite: { required: true, number: true, range: [1, 31] },
				jour_fin_activite: { required: true, number: true, range: [1, 31] },
				nb_jours_activite: { required: true, min: 1 },
				salaire_brut_activite: { required: true, number: true, min: 1 }
			},
			{
				nb_jours_activite: { min: "Merci d'écrire une valeur supérieur à 0." },
				salaire_brut_activite: { min: "Merci d'écrire une valeur supérieur à 0." }
			});

		/**
		 * Clique sur le bouton "Ajouter une date" Dans Outils Pôle emploi
		 * Affiche la fenêtre modale de sauvegarde de la date
		 */
		this.I('cdgBtnAjouterDateTravaileeExterne').click(() => {
			let today = new Date();

			// On initialise touts les champs avec les valeurs par défaut
			this.I('fdeIdDateTravaillee').val(0);
			this.I('fdeJourFin').val(today.getMonth() + 1);
			this.I('fdeAnnee').val(today.getFullYear());
			this.I('fdeJourDebut').val(0);
			this.I('fdeJourFin').val(0);
			this.I('fdeNbJours').val(0);
			this.I('fdeNbHeures').val(0);
			this.I('fdeNbCachets').val(0);
			this.I('fdeNbJours').val(0);

			// On affiche la fenêtre modale
			this.I('fenetreSauvegardeDateExterne').modal('show');
		});

		/**
		 * Changement de la catégorie salariale de l'édition d'une date travaillée
		 */
		this.I('fdeCategorieSalariale').change(() => {
			let disableCachet = false;
			let disableJours = false;

			switch (parseInt(this.I('fdeCategorieSalariale').val())) {
				case CATEGORIE_SALARIALE.ENSEIGNEMENT : 
					disableCachet = true;
					break;
					
				case CATEGORIE_SALARIALE.ARTISTE :
					disableJours = true;
					break;
					
				case CATEGORIE_SALARIALE.TECHNICIEN :
					disableCachet = true;
					break;
			}

			// On désactive les cachets
			if (disableCachet) {
				this.I('fdeNbCachets').val(0);
			}
			
			this.I('fdeNbCachets').prop("disabled", disableCachet);

			// On désactive les jours
			if (disableJours) {
				this.I('fdeNbJours').val(0);
			}
			
			this.I('fdeNbJours').prop("disabled", disableJours);		
		});

		// On execute le changement de catégorieaprès son initialisation
		this.I('fdeCategorieSalariale').trigger('change');

		// Initialisation des flatpikers
		flatpickr('#date_examen_anticipe', {dateFormat: 'd/m/Y', altFormat: 'd/m/Y'});

		/**
		 * Regroupe mes évènements click de la page afin de dispatcher les actions en optimisant le code
		 * @param {Event} p_event Evenement associé
		 */
		$(document).on('click', 'i', (p_event) => {
			p_event.preventDefault();

			const $target = $(p_event.currentTarget);
			const action = $target.attr('name'); // L'attribut 'name' est utilisé pour identifier l'action

			switch (action) {
				case 'editDateTravailleeExterne':
					this.onPoleEMploiEditerDateTravailleeExterne($target);
					break;

				case 'removeDateTravailleeExterne' :
					this.onPoleEMploiRemoveDateTravailleeExterne($target);
					break;

				case 'switchActivitePoleEmploi':
					this.onPoleEmploiSwitchActivity($target);
					break;
			}
		});
	}
		
	//------------------//
	//-- MES DOSSIERS --//
	//------------------//
	
	/**
	 * Filtre les recherche de dossier
	 */
	onMesDossiersFiltersChanged() {
		Ajax.postAsync(
			'AppSalarie/filtreDossier', {
				statut: $("#form-recherche-dossier-statut").val(),
				nom_dossier: $('#form-recherche-dossier-nom').val(),
				date: $("#form-recherche-dossier-date").val(),
				redirect: $('#current-url').val()
			},
			(p_event) => Ajax.defaultAsyncJSONSuccessCallback(p_event),
			(p_event) => Ajax.defaultAsyncJSONErrorCallback(p_event)
		);
	}
	
	onEnvoyerMessageDossier(p_event) {
		p_event.preventDefault();

		let numDossier = $(p_event.currentTarget).attr('data-num_dossier');			

		$.blockUI();
		Ajax.postAsync(
			'AppSalarie/envoyerMessageDossier', {
				num_dossier: numDossier,
				message_du_salarie: $('#message-' + numDossier).val()
			}, 

			(p_result) => {
				Ajax.defaultAsyncJSONSuccessCallback(p_result, UNBLOCK_UI);
				
				const idModal = "#modal-" + numDossier;
				
				// On simuler un clic sur le bouton "Fermer" car la fonction hide ne réactiva pas le fond
				const closeButton = $('#modal-' + numDossier + ' [data-bs-dismiss="modal"]');
				if (closeButton.length) {
					closeButton.trigger('click'); 
				}
			},

			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI)
		);
	}
	
	//---------------//
	//-- MES PAIES --//
	//---------------//
	
	onMesPaiesFiltersChanged() {		
		Ajax.postAsync(
			'AppSalarie/filtrePaies', {
				date_begin:$("#form-recherche-paie-date-begin").val(),
				date_end:$("#form-recherche-paie-date-end").val(),
				date_virement:$("#form-recherche-paie-date-virement").val(),
				profession:$("#form-recherche-paie-profession").val(),
				redirect:$('#current-url').val()
			},
			(p_event) => Ajax.defaultAsyncJSONSuccessCallback(p_event),
			(p_event) => Ajax.defaultAsyncJSONErrorCallback(p_event)
		);
	}
	
	//------------------//
	//-- MES SOCIETES --//
	//------------------//

	/**
	 * Lance une recherche des sociétés et affiche le résultat
	 * @param {Event} p_event Evenement associé
	 * @param {String} p_ordering chaine contenant le nom de champ à trier
	 */
	onMesSocietesFiltersChanged(p_event, p_ordering) {
		Ajax.postAsync(
			'AppSalarie/getTplListingSociete', {
				ordering: p_ordering,
				filter: $("#input-filter-societes").val()
			},
			
			(result) => {
				$('#table-results-societes').html(result.html); 
			},
			
			Ajax.defaultAsyncJSONErrorCallback
		);
	}
		
	//-----------------//
	//-- POLE EMPLOI --//
	//-----------------//
	
	/**
	 * Edition d'une date travaillée
	 * Affiche la fenêtre modale de sauvegarde de la date avec les valeures de celle-ci
	 * Délégation de l'évenement via "$(document).on()" afin de gérer les ajouts dynamiques
	 * @param {dom item} p_targetItem item concerné par cet appel de fonction
	 */
	onPoleEMploiEditerDateTravailleeExterne(p_targetItem) {
		let crptIdDateTravailleeExterne = p_targetItem.attr('data-id-date-travaillee-externe');

		Ajax.postAsync(
			'AppCompteSalarie/calcDeclaGouvLoadDateExterne', {
				crptIdDateTravailleeExterne: crptIdDateTravailleeExterne
			},

			(p_result) => {
				let explDateDebut = p_result.dateExterne.date_debut.split('-');
				let explDateFin = p_result.dateExterne.date_fin.split('-');

				this.I('fdeIdDateTravaillee').val(crptIdDateTravailleeExterne);
				this.I('fdeEmployeur').val(p_result.dateExterne.nom);
				this.I('fdeCategorieSalariale').val(p_result.dateExterne.categorie_salariale);
				this.I('fdeJourFin').val(parseInt(explDateDebut[1]));
				this.I('fdeAnnee').val(explDateDebut[0]);
				this.I('fdeJourDebut').val(explDateDebut[2]);
				this.I('fdeJourFin').val(explDateFin[2]);
				this.I('fdeNbJours').val(p_result.dateExterne.nb_jours);
				this.I('fdeNbHeures').val(p_result.dateExterne.nb_heures);
				this.I('fdeNbCachets').val(p_result.dateExterne.nb_cachets);
				this.I('fdeSalaireBrut').val(p_result.dateExterne.total_brut);

				this.I('fdeCategorieSalariale').trigger('change');

				this.I('fenetreSauvegardeDateExterne').modal('show');
			},

			Ajax.defaultAsyncJSONErrorCallback
		);
	}

	/**
	 * On clique sur le supression d'une date travaillée
	 * Délégation de l'évenement via "$(document).on()" afin de gérer les ajouts dynamiques
	 * @param {dom item} p_targetItem item concerné par cet appel de fonction
	 */
	onPoleEMploiRemoveDateTravailleeExterne(p_targetItem) {
		let crptIdDateTravailleeExterne = p_targetItem.attr('data-id-date-travaillee-externe');

		Ajax.postAsync(
			'AppCompteSalarie/calcDeclaGouvSupprimerDateExterne', {
				crptIdDateTravailleeExterne: crptIdDateTravailleeExterne
			}, 
			(p_event) => Ajax.defaultAsyncJSONSuccessCallback(p_event),
			(p_event) => Ajax.defaultAsyncJSONErrorCallback(p_event)
		);
	}

	/**
	 * Switch l'affichage du détail d'un mois dans le listing de dates travaillées
	 * Délégation de l'évenement via "$(document).on()" afin de gérer les ajouts dynamiques
	 * @param {dom item} p_targetItem item concerné par cet appel de fonction
	 */
	onPoleEmploiSwitchActivity(p_targetItem) {
		let id = p_targetItem.attr('data-linked-id');
		let element = $('#' + id);
		$(element).toggle(500);
	}
}

//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------- DOCUMENT READY -------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//

$(document).ready(function() {
	const compteSalarie = new CompteSalarie();
});