"use strict";

import { Debug } from './debug.js';

/* Section pour virer les warnings de netbeans concernant les variables globales*/
/* global toastr */

window.UNBLOCK_UI = true;

export class Ajax {
	
	//---------------------//
	//-- DONNEES MEMBRES --//
	//---------------------//
	
	static m_toastrMessagesDuration = 3000;

	//----------------//
	//-- ACCESSEURS --//
	//----------------//

	static toastrMessagesDuration() {
		return Ajax.m_toastrMessagesDuration;
	}

	//------------//
	//-- DIVERS --//
	//------------//

	/**
	 * Prépare les paramètres en ajoutant les champs optionnels dynamiquement.
	 * 
	 * @param {Object} p_params - Objet contenant les paramètres obligatoires.
	 * @param {Array} p_optionalFields - Tableau d'objets { sel: 'elementId', paramName: 'variableName' }.
	 * @returns {Object} - Objet complet avec les paramètres obligatoires et optionnels ajoutés.
	 */
	static addFieldsToParams(p_params, p_optionalFields) {
		p_optionalFields.forEach(field => {
			const $element = $(field.sel); // Récupère l'élément jQuery par son ID
			if ($element.length > 0) { // Vérifie si l'élément existe dans le DOM
				if ($element.is(':checkbox')) { 
					// Si c'est un checkbox, la valeur sera 0 ou 1
					p_params[field.paramName] = $element.is(':checked') ? 1 : 0;
				} else {
					// Sinon, récupère directement la valeur normale de l'élément
					p_params[field.paramName] = $element.val();
				}
			}
		});
		return p_params;
	}
	
	static createParams(p_optionalFields) {
		let params = {};
		
		return Ajax.addFieldsToParams(params, p_optionalFields);
	}
	
	/**
	 * Execute une requette ajax de façon asynchone
	 * @param p_url URL à appeler
	 * @param p_data donnees à transmettre
	 * @param p_success callBack de success
	 * @param p_fail callBack d'erreur
	 */
	static postAsync(p_url, p_data, p_success, p_fail) {
		Ajax.post(p_url, p_data, p_success, p_fail, true);
	}

	/**
	 * Helper pour les appel ajax
	 * @param p_url URL à appeler
	 * @param p_data donnees à transmettre
	 * @param p_success callBack de success
	 * @param p_fail callBack d'erreur
	 * @param p_async boolean qui défini si la fonction est synchrone ou asynchrone
	 */
	static post(p_url, p_data, p_success, p_fail, p_async) {
		
		if (p_async === undefined) {
			p_async = false;
		}

		if (p_url.startsWith(SITE_URL) !== true) {
			p_url = SITE_URL + p_url;
		}

		$.ajax({
			url: p_url,
			type: 'POST',
			dataType: "text",
			async : p_async,
			data: p_data,
			dataFilter: function (p_data, p_type) {
				try {
					return JSON.parse(p_data);
				}
				catch (p_exception) {
					Debug.log('JSON invalid ' + p_data);
					return { 
						success:false, 
						message:'Erreure interne'
					};
				}
			},
			
			success: function (p_result) {
				if (p_result.success) { p_success(p_result); }
				else { if (p_fail) { p_fail(p_result); } }
			},
			
			error: function(p_result) {
				if (p_fail) { p_fail(p_result); }
			}
		});
	}

	/**
	 * Gere une barre de progression sur un upload de fichier
	 * Fonctionnement synchrone
	 * @param p_url chaine de caractère contenant l'URL à appeler pour l'upload
	 * @param p_formData données envoyées à la requette AJAX de type FormData
	 * @param p_success fonction appelée en cas de succès
	 * @param p_fail fonction appelée en cas d'erreur
	 * @param p_progress fonction callback qui est appelé lors de la progression du chargement
	 * @returns {undefined}
	 */
	static upload(p_url, p_formData, p_success, p_fail, p_progress) {
		
		if (p_url.startsWith(SITE_URL) !== true) {
			p_url = SITE_URL + p_url;
		}
		
		$.ajax(
		{
			url: p_url,
			dataType: 'json',
			cache: false,
			contentType: false,
			processData: false,
			data: p_formData,
			type: 'post',
			async: false,
			
			xhr: function () {
				let xhr = new window.XMLHttpRequest();
				
				xhr.upload.addEventListener("progress", function (p_evt) {
						if (p_progress) {
							if (p_evt.lengthComputable) {
								let percentComplete = p_evt.loaded / p_evt.total;
								percentComplete = parseInt(percentComplete * 100);

								p_progress(percentComplete);
							}
						}
					}, false);
				return xhr;
			},
			
			success: function (p_result) {
				if (p_result.success) { 
					p_success(p_result); 
				} else { 
					if (p_fail) {
						p_fail(p_result);
					} 
				}
			},
			
			fail: function(p_result) {
				if (p_fail) {
					p_fail(p_result);
				}
			}
		});
	}
	
	//---------------------------//
	//-- DEFAULT JSON HANDLERS --//
	//---------------------------//

	static checkJSONResultredirect(p_result)
	{
		let msgDuration = p_result.msgDuration ? p_result.msgDuration : this.m_toastrMessagesDuration;

		if (p_result.redirect) {
			if (p_result.message) {
				setTimeout(function() {
						window.location = p_result.redirect; 
					}, msgDuration);
			}
			else {
				window.location = p_result.redirect;
			}
		}
	}

	/**
	 * Fonction qui gère les erreures emises par un appel Ajax
	 * @param {type} p_result objet resultat contenant les variable : msgDuration, message
	 * @param {boolean} p_unblockUi si vrai alors l'UI est débloqué après execution 
	 */
	static defaultAsyncJSONErrorCallback(p_result, p_unblockUi=false) {
		
		let msgDuration = p_result.msgDuration ? p_result.msgDuration : this.m_toastrMessagesDuration;
		toastr.options.timeOut = msgDuration;

		if (p_unblockUi) {
			$.unblockUI();
		}
		
		if (p_result.message) {
			toastr.error(p_result.message, function() {
				Ajax.checkJSONResultredirect(p_result); 
			});
		}
		else {
			Ajax.checkJSONResultredirect(p_result);
		}
	};

	/**
	 * Fonction qui gère les retours emis par un appel Ajax
	 * @param {type} p_result objet resultat contenant les variable : msgDuration, message
	 * @param {boolean} p_unblockUi si vrai, alors l'Ui est débloquée
	 */
	static defaultAsyncJSONSuccessCallback(p_result, p_unblockUi=false) {
		
		// Si on à défini une durés spécifique pour l'affichage du message
		if (p_result.msgDuration) {
			toastr.options.timeOut = p_result.msgDuration;
		}
		// Sinon, si on à un message à afficher on utilise la durée par défaut
		else if (p_result.message) {
			toastr.options.timeOut =  this.m_toastrMessagesDuration;
		}
		// Sinon il n'y à pas de durée de message
		else {
			toastr.options.timeOut = 0;
		}

		if (p_unblockUi) {
			$.unblockUI();
		}

		// Si on à un message à afficher
		if (p_result.message) {
			toastr.success(p_result.message, function() { 
				Ajax.checkJSONResultredirect(p_result); 
			});
		}
		// Sinon on redirige sans message
		else {
			Ajax.checkJSONResultredirect(p_result);
		}
	}
}