"use strict";

import { Ajax } from './ajax.js';
import { Dom } from './dom.js';
import { Debug } from './debug.js';
import { PFHBaseClass } from './pfhBaseClass.js';

export class RechercheSalarie extends PFHBaseClass {
	
	//---------------------//
	//-- DONNEES MEMBRES --//
	//---------------------//
	
	m_idDemande = 0;
	
	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//
	
	/**
	 * Initialise les évenements communs a toutes les pages
	 * @param p_idRechercheSalarie id du champ de recherche de salarie defaut salarie-recherche
	 */
	constructor(p_idRechercheSalarie = 'salarie-recherche') {
		super(); // Appelle le constructeur de PFHBaseClass

		this.m_sel = {
			mainItem: `#${p_idRechercheSalarie}`,
			result: `#${p_idRechercheSalarie} [name="salarie-recherche-result"]`,
			input: `#${p_idRechercheSalarie} [name="salarie-recherche-txt"]`,
			aide: '#salarie-recherche-aide'
		};
		
		// ACTION : on tape au clavier pour effectuer la recherche de salaries
		$(this.m_sel.input).keyup((p_event) => this.onSalarieRechercheUpdated(p_event));		
	}
	
	//---------------------//
	//-- ACCESSEURS ITEM --//
	//---------------------//

	item() {
		return this.I('mainItem');
	}
	
	//----------------//
	//-- ACCESSEURS --//
	//----------------//
	
	getValue() {
		return this.I('input').val();
	}
	
	idDemande() {
		return this.m_idDemande;
	}
	
	//-------------//
	//-- SETTERS --//
	//-------------//
	
	setIdDemande(p_idDemande) {
		this.m_idDemande = p_idDemande;
	}
	
	setValue(p_value) {
		this.I('input').val(p_value);
	}
	
	//------------//
	//-- DIVERS --//
	//------------//
	
	/**
	 * Efface le champ des sélécteurs
	 * @returns {undefined}
	 */
	clearFields() {
		this.setValue('');
		this.setResultVisibile(false);
	}
	
	/**
	 * Rend l'aide visible ou invisible
	 * @param {boolean} p_visible vrai si on souhaite rendre l'item visible
	 */
	setAideVisibile(p_visible) {
		Dom.setVisibility(this.I('aide'), p_visible);
	}
	
	/**
	 * Rend le résultat de la recherche visible ou invisible
	 * @param {boolean} p_visible vrai si on souhaite rendre l'item visible
	 */
	setResultVisibile(p_visible) {
		Dom.setVisibility(this.I('result'), p_visible);
	}
	
	/**
	 * Rend l'objet général visible ou invisible
	 * @param {boolean} p_visible vrai si on souhaite rendre l'item visible
	 */
	setVisibile(p_visible) {
		Dom.setVisibility(this.item(), p_visible);
	}
	
	//------------//
	//-- EVENTS --//
	//------------//
	
	/**
	 * Effectue une recherche de salarié et affiche le résultat
	 */
	onSalarieRechercheUpdated() {
		var searchNom = this.getValue();

		// Si rien n'est saisi, on efface la zone de résultat
		if (searchNom.length === 0){
			this.setResultVisibile(false);
		}
		
		// On effectue la recherche que si on à taper 3 lettres minimum
		if (searchNom.length >= 3){
			Ajax.postAsync(
				
				'AppEmbauche/autocompleteChercheSalarie', {
					searchNom: searchNom,
					idDemande: this.idDemande()
				}, 
				
				(p_result) => {
					// On affiche les résultats
					this.I('result').html(p_result.html);
					this.setResultVisibile(true); // On affiche les différents résultats de recherche

					// On configure les évenements du click pour le résultat
					$(this.m_sel.result + ' ul li a').click((p_event) => {
							p_event.preventDefault();
							this.item().trigger('rechercheSalarieResultClicked', $(p_event.currentTarget));
						});
	
					this.item().trigger('rechercheSalarieSuccess', p_result);
				},
				
				(p_result) => {
					this.setResultVisibile(false);
					this.item().trigger('rechercheSalarieError', p_result);
					
					Ajax.defaultAsyncJSONErrorCallback(p_result);
				}
			);
		}
	}
}
