"use strict";

/* Section pour virer les warnings de netbeans concernant les variables globales*/
/* global toastr */

import { Ajax } from './ajax.js';
import { Dom } from './dom.js';
import { Debug } from './debug.js';
import { PFHBaseClass } from './pfhBaseClass.js';

export class ProjetSalariePageDocs extends PFHBaseClass {
	
	//---------------------//
	//-- DONNEES MEMBRES --//
	//---------------------//

	m_templateAnnexe = null; // Template d'une ligne d'annexe
	m_templateFrais = null; // Template d'une ligne de frais
	m_baseCalculFrais = null; // Variable contenant la dernière base de calcul utilisé pour les frais ("cout-ht", "cout-ttc", "tva")	

	m_fMsg = {		
		AjoutFrais: {
			"libelle": { required: "Veuillez indiquer le libelle de la facture" },
			"cout-ht": { required: "Veuillez indiquer le cout HT de la facture", number: "Veuillez saisir un montant valide" },
			"cout-ttc": { required: "Veuillez indiquer le cout TTC de la facture", number: "Veuillez saisir un montant valide" },
			"tva": { required: "Veuillez indique la TVA" },
			"type-frais": { required: "Merci d'indiquer le type de facture" }
		}
	};

	m_fRules = {	
		AjoutFrais: {
			"libelle": { required: true },
			"cout-ht": { required: true, number: true },
			"cout-ttc": { required: true, number: true },
			"tva": { required: true, number: true },
			"type-frais": { required: true }
		}
	};
		
	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//
	
	constructor() {
		super(); // Appelle le constructeur de PFHBaseClass	
	
		const tableTelechargementFrais = '#projet-list-frais-table';
		const formFrais = '#form-frais-projet';
		
		const tableTelechargementAnnexe = '#projet-list-annexe-table';
		const formAnnexe = '#form-annexe-projet';
		
		this.m_sel = {
			btnOuvrirPopinFrais: '#btn-ajouter-annexe-facture',
			popinAjouterFrais: '#popin-ajout-facture',
			btnChoisirFrais: `${formFrais} [name="btn-choisir-frais"]`,
			zoneFraisJustifSelected: `${formFrais} [name="zone-justif-selected"]`,
			txtFraisJustifSelected: `${formFrais} [name="txt-justif-selected"]`,
			btnFraisRemoveJustif: `${formFrais} [name="btn-remove-justif"]`,
			ifFraisFile: `${formFrais} input[type="file"]`,
			btnAjouterFrais: '#popin-ajout-facture-valider',
			formFrais: formFrais, // Formulaire d'ajout d'un frais
			ifFraisHt: `${formFrais} [name="cout-ht"]`,
			ifFraisTva: `${formFrais} [name="tva"]`,
			ifFraisTvaSel: `${formFrais} [name="tva"] option:selected`,
			ifFraisTtc: `${formFrais} [name="cout-ttc"]`,
			listeTelechargementFrais: '#projet-list-frais',
			tableTelechargementFrais: tableTelechargementFrais,
			tplFrais: `${tableTelechargementFrais} [role="template"]`,
			
			btnAjouterAnnexe: '#btn-ajouter-annexe-document',			
			formAnnexe: formAnnexe, // Formulaire d'ajout d'une annexe
			ifAnnexeFile: `${formAnnexe} input[type="file"]`,
			listeTelechargementAnnexe: '#projet-list-annexe',
			tableTelechargementAnnexe: tableTelechargementAnnexe,
			tplAnnexe: `${tableTelechargementAnnexe} [role="template"]`
		};
		
		//-------------------//
		//-- ACTIONS FRAIS --//
		//-------------------//
		
		// On clique sur ajouter une facture
		this.I('btnOuvrirPopinFrais').click((p_event) => {
			p_event.preventDefault();
			// On affiche le popin
			this.I('popinAjouterFrais').modal('show'); 
		});

		// On clique sur ajouter un frais
		this.I('btnAjouterFrais').click(() => { 
			this.I('formFrais').submit(); 
		});

		// Affiche le nom du fichier chargé dans la zone de texte lorsque l'on à séléctionné un fichier
		this.I('ifFraisFile').change((p_event) => {
			const currentTarget = $(p_event.currentTarget);
			const fullPath = currentTarget.val(); // C:\fakepath\monfichier.pdf
			const fileName = fullPath.split('\\').pop(); // monfichier.pdf
			
			this.I('txtFraisJustifSelected').val(fileName);
			Dom.setVisibility(this.I('zoneFraisJustifSelected'), true);
		});

		// On clique sur joindre le justificatif
		this.I('btnChoisirFrais').click((p_event) => {
			p_event.preventDefault();
			// On affiche la fenêtre de choix du fichier
			this.I('ifFraisFile').trigger('click');
		});

		// On clique sur supprimer le justificatif
		this.I('btnFraisRemoveJustif').click((p_event) => {
			p_event.preventDefault();
			
			this.I('ifFraisFile').val('');
			Dom.setVisibility(this.I('zoneFraisJustifSelected'), false);
		});
		
		// Mise à jours automatique des montants si on change :
		// le HT, le TTC ou bien la TVA dans la page frais
		this.I('formFrais').on('change', '[name="cout-ht"],[name="cout-ttc"],[name="tva"]', (p_event) => {

			const currentItem = p_event.currentTarget;
			
			// Si on modifie la tva mais que l'on à pas défini de HT ou de TTC
			// On ne fait rien
			if (this.m_baseCalculFrais === null && currentItem.name === 'tva') {
				return;
			}
			
			// On conserve le dernier mode de calcul en mémoire
			if (currentItem.name !== 'tva') {
				this.m_baseCalculFrais = currentItem.name;
			}
		
			const tva = this.fraisTva();
		
			// On calcul depuis le cout ttc
			if (this.m_baseCalculFrais === 'cout-ttc') {
				const ttc = this.fraisTtc();
				const ht = ttc / (1 + tva / 100);
				
				this.setFraisHt(parseInt(ht * 100) / 100);
				Dom.checkDataRequired(this.I('ifFraisHt'));
				
			// Par défaut on calcul depuis le cout ht
			} else { 
				const ht = this.fraisHt();
				const ttc = ht * (1 + tva / 100);
				
				this.setFraisTtc(parseInt(ttc * 100) / 100);
				Dom.checkDataRequired(this.I('ifFraisTtc'));
			}
		});

		// CONFIGURATION : Configuration du formulaire d'upload de frais
		this.I('formFrais').validate({
			errorElement: 'div',
			focusInvalid: false,
			rules: this.m_fRules.AjoutFrais,
			messages: this.m_fMsgAjoutFrais,
			submitHandler: () => { this.onUploadFraisRequested(); },
			invalidHandler: (p_event, p_validator) => Dom.defaultAsyncInvalidHandler(p_event, p_validator)
		});

		//---------------------//
		//-- ACTIONS ANNEXES --//
		//---------------------//

		// On clique sur ajouter une annexe
		this.I('btnAjouterAnnexe').click((p_event) => {
			p_event.preventDefault();
			// On affiche la fenêtre de choix du fichier
			this.I('ifAnnexeFile').trigger('click');
		});

		// l'input file d'une annexe à été modifiée
		this.I('ifAnnexeFile').change((p_event) => this.onUploadAnnexeRequested(p_event));

		//-------------------------//
		//-- ELEMENTS DYNAMIQUES --//
		//-------------------------//

		// Gestion des clicks sur les éléments dynamiques
		$(document).on('click', '[name="supprimer-frais"], [name="supprimer-annexe"]', (p_event) => {
			const $this = $(this);
			const name = $(p_event.currentTarget).attr('name');

			switch (name) {
				case 'supprimer-frais' :
					this.removeFile('frais', p_event.currentTarget);
					break;
				case 'supprimer-annexe' :
					this.removeFile('annexe', p_event.currentTarget);
					break;
			}
		});
		
		//------------------------------//
		//-- SCRIPTS D'INITIALISATION --//
		//------------------------------//

		// ANNEXES
		// On sauvegarde, puis on rend invisible les templates que l'on va utiliser
		this.m_templateAnnexe = this.getTemplateFromId(this.S('tplAnnexe'));
		this.m_templateFrais = this.getTemplateFromId(this.S('tplFrais'));
	}
	
	//----------------//
	//-- ACCESSEURS --//
	//----------------//
	
	fraisHt() {
		const ht = this.I('ifFraisHt').val();
		// On vérifie si `tva` est défini et est un nombre valide
		if (!ht || isNaN(ht)) {
			return parseFloat(0);
		}
		
		return parseFloat(ht);
	}
	
	fraisTtc() {
		const ttc = this.I('ifFraisTtc').val();
		
		// On vérifie si `tva` est défini et est un nombre valide
		if (!ttc || isNaN(ttc)) {
			return parseFloat(0);
		}
		
		return parseFloat(ttc);
	}
	
	fraisTva() {
		const tva = parseFloat(this.D('ifFraisTvaSel').text());

		// On vérifie si `tva` est défini et est un nombre valide
		if (!tva || isNaN(tva)) {
			return parseFloat(0.00);
		}
		
		return tva;
	}
	
	//-------------//
	//-- SETTERS --//
	//-------------//
	
	setFraisHt(p_value) {
		parseFloat(this.I('ifFraisHt').val(p_value));
	}
	
	setFraisTtc(p_value) {
		parseFloat(this.I('ifFraisTtc').val(p_value));
	}

	//--------------------------//
	//-- GESTION DES FICHIERS --//
	//--------------------------//
	
	/**
	 * ATTENTION ASYNCHRONE
	 * Fonction appelée lorsque l'on souhaite supprimer un frais ou une annexe
	 * @param {type} p_type type de fichier : 'frais' ou 'annexe'
	 * @param {type} p_element
	 */
	removeFile(p_type, p_element) {
		p_element = $(p_element);
		
		$.blockUI();
		
		let action = '';
		switch (p_type) {
			case 'annexe' :
				action = 'AppEmbauche/demandeSupprimeDocumentAnnexe';
				break;
				
			case 'frais' :
				action = 'AppEmbauche/demandeSupprimeDocumentFrais';
				break;
			
			default :
				return;
		}
		
		Ajax.postAsync (
			action, {
				ref: p_element.attr('data-info')
			}, 
			
			(p_result) => {
				// Si on supprime le dernier élément dans le tableau on ne l'affiche plus
				if (p_element.parents('tbody').find('tr').length === 1) {
					Dom.setVisibility($('#projet-list-' + p_type), false);
				}

				// On supprime la ligne du tableau
				p_element.trigger('mouseout').parents('tr').remove();

				$.unblockUI();
				toastr.success(p_result.message); // On affiche le message
			},
			
			(p_result) => {
				$.unblockUI();
				Ajax.defaultAsyncJSONErrorCallback(p_result);
			}
		);
	}
	
	/**
	 * Télécharge un fichier nommé file dans un formulaire
	 * @param {type} p_idForm
	 * @param {type} p_uploadFunc
	 * @param {type} p_createItemfunc
	 * @returns {undefined}
	 */
	uploadFormFile(p_idForm, p_uploadFunc, p_createItemfunc) {
		$.blockUI();
		
		var form = $(p_idForm);

		// création des données formulaires à envoyer
		var formData = new FormData();

		// On ajoute les paramètres contenues dans les input et les select du formulaire
		form.find('input,select').each(function() {
			formData.append($(this).attr('name'), $(this).val()); 
		});

		// On ajoute le fichier à uploader sous le nom de paramètre 'userfile'
		var files = $(form).find('[name="file"]').prop('files');
		formData.append('userfile', files[0]);
				
		// On upload le fichier
		Ajax.upload(
			p_uploadFunc, 
			formData, 
			
			(p_result) => {
				if (p_result.success) {
					p_createItemfunc(p_result, form);
					toastr.success(p_result.message);						
				}
				else {
					toastr.error(p_result.message); 
				}
			}
		);
		
		$.unblockUI();
	}
	
	//----------------//
	//-- EVENEMENTS --//
	//----------------//

	onUploadFraisRequested() {
		this.uploadFormFile(
			this.m_sel.formFrais, 
		
			//this.m_sel.tableTelechargementFrais, 
			
			'AppEmbauche/demandeAjouteDocumentFrais', 
			
			(p_result, p_form) => {
				var fraisLine = $(this.m_templateFrais
					.replace('$nom', p_form.find('[name="libelle"]').val())
					.replace('$base', p_form.find('[name="cout-ht"]').val())
					.replace('$tva', p_form.find('[name="tva"] option:selected').text())
					.replace('$ttc', p_form.find('[name="cout-ttc"]').val())
					.replace('$codet', p_form.find('[name="type-frais"] option:selected').text())
					.replace('$code', p_form.find('[name="type-frais"]').val())
					.replace('$ref', p_result.ref)
					.replace('$justificatif', p_result.name));
					
				fraisLine.removeAttr('role');

				// On créer une nouvelle ligne et on l'ajoute
				this.I('tableTelechargementFrais').append(fraisLine);

				// On a ajouter un élément, on cache donc le 'aucune annexe'
				Dom.setVisibility($(this.S('tableTelechargementFrais') + ' tr.empty-table'), false);

				// On vide le formulaire
				Dom.clearForm(p_form);

				// On affiche le listing
				Dom.setVisibility(this.I('listeTelechargementFrais'), true);
				//return fraisLine;
				});
		
		// @TODO uniquement si l'upload se passe bien
		// On affiche le listing
		//Dom.setVisibility(this.iListeTelechargementFrais(), true);
		
		// On ferme le popin
		$('#popin-ajout-facture').modal('hide');
		
		return false;
	}
	
	onUploadAnnexeRequested() {
		this.uploadFormFile(
			
		this.m_sel.formAnnexe, 

		'AppEmbauche/demandeAjouteDocumentAnnexe',

		(p_result, p_form) => {
			// On créer une nouvelle ligne et on l'ajoute
			var annexeLine = $(this.m_templateAnnexe
				.replace('$nom', p_result.fileName)
				.replace('$ref', p_result.ref));

			annexeLine.removeAttr('role');

			// On créer une nouvelle ligne et on l'ajoute
			this.I('tableTelechargementAnnexe').append(annexeLine);
			
			// On a ajouter un élément, on cache donc le 'aucune annexe'
			Dom.setVisibility($(this.S('tableTelechargementAnnexe') + ' tr.empty-table'), false);

			// On vide le formulaire
			Dom.clearForm(p_form);

			// On affiche le listing
			Dom.setVisibility(this.I('listeTelechargementAnnexe'), true);
			//return annexeLine;
		});
		
		return false;
	}
	
	//------------//
	//-- DIVERS --//
	//------------//
	
	getTemplateFromId($p_idTemplate) {
		const item = $($p_idTemplate);
		const template = $('<p/>').html(item).html();
		Dom.setVisibility(item, false);
		return template;
	}
}