"use strict";

import { Ajax } from './ajax.js';
import { Dom } from './dom.js';
import { Debug } from './debug.js';
import { PFHBaseClass } from './pfhBaseClass.js';
import { Slider } from './slider.js';


export class ItemCatalogue extends PFHBaseClass {
	//---------------------//
	//-- DONNEES MEMBRES --//
	//---------------------//
	
	m_slider=null;
	
	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//
	
	constructor() {
		super(); // Appelle le constructeur de PFHBaseClass	
		
		this.m_sel = {		
			// Modale
			popinDetail: '#popin-detail-presta',
			detTitle: '#popin-title-detail-presta',
			detDescription: '#popin-detail-presta-description-txt',
			detPhoto1: '#popin-detail-presta-photo1',
			detPhoto2: '#popin-detail-presta-photo2',
			detThemeTxt: '#popin-detail-presta-theme-txt',
			detVhrIncDiv: '#popin-detail-presta-vhr-inc-div',
			detVhrIncTxt: '#popin-detail-presta-vhr-inc-txt',
			detVhrExcDiv: '#popin-detail-presta-vhr-exc-div',
			detVhrExcTxt: '#popin-detail-presta-vhr-exc-txt',
			detSlide: '#slideshow-catalogue',
			detSlideDots: '#slideshow-catalogue-dot'
		};
		
		this.m_slider = new Slider("slideshow-catalogue", "slideshow-catalogue-dot");
	}
	
	initSlider(p_lstPhotos) {
		// on ajoute les éléments
		const slideImages = [];
		const dotElements = [];
		let newSlider = '';
		let i=0;
		
		for (const photo of p_lstPhotos) {
			i++;
			slideImages.push(`
				<div name="slide" class="mySlides">
					<img id="popin-detail-presta-photo${i}" class="photo-fiche" src="${photo}">
				</div>`);
			dotElements.push(`<span name="slide-dot" class="dot" data-num-page="${i}"></span>`);
		}
		
		if (i > 0) {
			newSlider = `
				${slideImages.join('')}
				<div id="slideshow-catalogue-dot" style="text-align: center;">
					${dotElements.join('')}
				</div>`;
		}
			
		this.m_slider.stopAutoSlide();
		this.I('detSlide').html(newSlider);
		if (newSlider !== '') {
			this.m_slider.init("slideshow-catalogue", "slideshow-catalogue-dot");
			this.m_slider.showSlidesAuto();
		}
	}
	
	//----------------//
	//-- EVENEMENTS --//
	//----------------//
	
	onDynamicItemClicked(p_name, p_event) {
		switch (p_name) {
			case 'btn-show-detail-presta' :
				this.onBtnShowDetailPrestaClicked(p_event);
				break;
		}
	}
	
	onBtnShowDetailPrestaClicked(p_event) {
		p_event.preventDefault();
		
		const target = $(p_event.currentTarget);
		// Rechercher le parent avec un attribut name spécifique
		const element = target.closest('[name="detail-presta"]');
		
		const designation = element.find('[name="designation"]');
		const description = element.find('[name="description"]');
		const lstThemes = element.find('[name="lst-themes"]');
		const vhrInc = element.find('[name="vhr-inc"]');
		const vhrExc = element.find('[name="vhr-exc"]');
		
		// Configuration du popin		
		this.I('detTitle').html(designation.val());
		this.I('detDescription').html(description.val());
		this.I('detThemeTxt').html(lstThemes.val());
		this.I('detVhrIncTxt').html(vhrInc.val());
		Dom.setVisibility(this.I('detVhrIncDiv'), vhrInc.val() !== '');
		this.I('detVhrExcTxt').html(vhrExc.val());
		Dom.setVisibility(this.I('detVhrExcDiv'), vhrExc.val() !== '');

		// Configuration du slider
		this.initSlider([
			element.find('[name="photo1"]').val(), 
			element.find('[name="photo2"]').val()].filter(value => value !== ''));
		
		// On affiche le popin
		this.I('popinDetail').modal('show'); 
	}
}
