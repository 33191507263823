"use strict";

/* Section pour virer les warnings de netbeans concernant les variables globales*/
/* global toastr */

export class Debug {

	//---------------------//
	//-- DONNEES MEMBRES --//
	//---------------------//
	
	static m_level = 4;
	
	//----------//
	//-- LOGS --//
	//----------//
	
	static logFormConfig($p_form) {
		const formId= $p_form.attr('id');
		const formName= $p_form.attr('name');
		
		Debug.log(`FORM CONFIG : #${formId} name["${formName}"]`);

		const validator = $p_form.data('validator');
		
		if (validator) {
			if (validator.settings.submitHandler) {
				console.log("Handler de soumission :", validator.settings.submitHandler);
			}
			
			if (validator.settings.invalidHandler) {
				console.log("Handler d'invalidité :", validator.settings.invalidHandler);
			}
			
			if (validator.settings.rules) {
				console.log("Règles de validations :", validator.settings.rules);
			}
			
			if (validator.settings.messages) {
				console.log("Messages de validations :", validator.settings.messages);
			}
		} else {
			console.log("Validateur non attaché au formulaire.");
		}
	}
	
	static varDump(obj) {
		let out = '';
		for (let i in obj) {
			out += i + ": " + obj[i] + "\n";  
		}
		return out;
	}

	static dumpEventsConnected(p_object) {
		let output;

		$.each($._data(p_object, "events"), 
			function(p_eventType, p_eventList) {
				output = output + "\nON EVENT : " + p_eventType;

				$.each(p_eventList, 
					function(p_i, p_handler) {
						output = output + "\n" + p_handler.handler;
					}
				);
			}
		);

		return output;
	}

	static dumpEventsConnectedUsingId(p_object)
	{
		let output = "Event dump for object : " + p_object;	
		//output = output + "\n" + $(p_object)[0];
		output = output + dumpEventsConnected($(p_object)[0]);
		return output;
	}

	static log(p_message, p_stringify=false, p_level=0) {	
		// Si on à pas le niveau de déboguage on ne fait rien
		if (p_level > this.m_level) {
			return;
		}
		
		// Vérification du type de p_message
		if (p_stringify && typeof p_message === 'object' && p_message !== null) {
			// Tentative de conversion en JSON avec gestion des erreurs
			try {
				p_message = JSON.stringify(p_message, null, 2);
			} catch (error) {
				console.warn("Impossible de convertir l'objet en JSON : ", error);
			}
		}
				
		console.log(p_message);
	}
}