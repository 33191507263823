"use strict";

import { Debug } from './debug.js';
import { PFHBaseClass } from './pfhBaseClass.js';

export class Slider extends PFHBaseClass {
	
	//---------------------//
	//-- DONNEES MEMBRES --//
	//---------------------//
	
	m_slideIndex = 0;
	m_autoSlideDuration = 6000;
	m_autoSlideTimer = null; // Stocke l'identifiant du timer

	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//
	
	/**
	 * @param {string} p_sliderContainerId id du conteneur du slider
	 * @param {string} p_sliderDotContainerId id du conteneur des points du slider
	 */
	constructor(p_sliderContainerId, p_sliderDotContainerId) {
		super(); // Appelle le constructeur de PFHBaseClass	
		this.init(p_sliderContainerId, p_sliderDotContainerId);
	};
	
	init(p_sliderContainerId, p_sliderDotContainerId) {
		if (Object.keys(this.m_sel).length > 0) {
			this.I('dots').off('click'); // suprime l'ancien gestionnaire d'évènement
			this.m_sel = {};
		}
		
		this.m_sel = {
			slides: `#${p_sliderContainerId} [name="slide"]`,
			dots: `#${p_sliderDotContainerId} [name="slide-dot"]`
		};

		this.I('dots').click((p_event) => this.onDotClicked(p_event));
	}
	
	//-------------//
	//-- SETTERS --//
	//-------------//
	
	/**
	 * @param {int} p_slideIndex
	 */
	setCurrentSlide (p_slideIndex) {
		this.m_slideIndex = p_slideIndex;
		this.showSlide(this.m_slideIndex);
	}
	
	//------------//
	//-- EVENTS --//
	//------------//
	
	onDotClicked(p_event) {
		const index = $(p_event.target).attr('data-num-page');
		this.showSlide(parseInt(index));
	}
	
	//------------------------//
	//-- GESTION DES SLIDES --//
	//------------------------//
	
	showSlide(p_slideIndex) {		
		let slides = $(this.m_sel.slides);
	
		// On vérifie la validité de l'index (parcour en boucle)
		this.m_slideIndex = p_slideIndex;
		if (p_slideIndex > slides.length) { 
			this.m_slideIndex = 1; 
		}
		
		if (p_slideIndex < 1) {
			this.m_slideIndex = slides.length;
		}
		
		// On désactive tous les slides puis on active le slide à l'index donné
		slides.attr("style", "display:none");
		slides.eq(this.m_slideIndex-1).attr("style", "display:block");

		// On désactive tous les boutons puis on active le bouton à l'index donné	
		let dots = $(this.m_sel.dots);		
		dots.removeClass('active');
		dots.eq(this.m_slideIndex-1).addClass('active');
	}

	showSlidesAuto() {
		
		// On incrémente l'indice à afficher
		this.showSlide(this.m_slideIndex+1);
			
		// On attend avant de recharger le suivant
		// Utiliser une fonction fléchée pour conserver le contexte de `this`
		this.m_autoSlideTimer = setTimeout(() => this.showSlidesAuto(), this.m_autoSlideDuration);
	}
		
	stopAutoSlide() {
		if (this.m_autoSlideTimer !== null) {
			clearTimeout(this.m_autoSlideTimer); // Stoppe le timer
			this.m_autoSlideTimer = null; // Réinitialise l'identifiant
		}
	}
}