/* Section pour virer les warnings de netbeans concernant les variables globales*/
/* global toastr */
/* global CATEGORIE_SALARIALE*/

"use strict";

import { Ajax } from './ajax.js';
import { Dom } from './dom.js';
import { Debug } from './debug.js';
import { ProjetCommon } from './projetCommon.js';
import { CadreInfoEmbauche } from './itemCadreInfoEmbauche.js';
import { PFHBaseClass } from './pfhBaseClass.js';
import { ProjetSalariePageType } from './projetSalariePageType.js';
import { ProjetSalariePageDetail } from './projetSalariePageDetail.js';
import { ProjetSalariePageDocs } from './projetSalariePageDocs.js';

window.STEP = {
	TYPE: 1,
	CLIENT: 2,
	CLIENT_TROUVE: 3,
	CLIENT_INSCRIPTION: 4,
	DETAIL: 5,
	ANNEXES: 6,
	EMBAUCHE: 7,
	EMBAUCHE_INSCRIPTION_INTERMITTENT: 8,
	EMBAUCHE_SAISIE_CONTRAT: 9,
	RECAP: 11
};

class ProjetSalarie extends PFHBaseClass {
	//---------------------//
	//-- DONNEES MEMBRES --//
	//---------------------//
	
	m_fMsg = {
		AjoutClient: {
			nomClient: { required: "Merci de renseigner le nom de la société" },
			codePostal: { required: "Merci de renseigner le code postal" }
		},
		
		InscriptionClient: {
			identifiant: { required: "Merci d'écrire un identifiant" },
			mot_de_passe: { required: "Merci d'écrire un mot de passe" },
			nom_societe: { required: "Merci d'écrire le nom de la structure" },
			forme_juridique: { required: "Merci d'écrire une forme juridique" },
			fonction_contact: { required: "Merci de choisir la fonction" },
			id_civilite_contact: { required: "Merci de choisir sa civilité" },
			email_contact: { required: "Merci d'écrire son email", email: "L'email n'est pas valide", different_utilisateur: "Merci d'écrire un email différent de votre email" },
			telephone_contact: { required: "Merci d'écrire un numéro de téléphone", number: "Merci d'écrire uniquement des chiffres" },
			nom_contact: { required: "Merci d'écrire le nom du contact" },
			prenom_contact: { required: "Merci d'écrire le prénom du contact" },
			rue1_ad: { required: "Merci d'écrire une adresse" },
			code_postal_ad: { required: "Merci d'écrire un code postal" },
			ville_ad: { required: "Merci d'écrire une ville" },
			id_pays_ad: { required: "Merci de choisir un pays", number: "Merci de choisir un pays" },
			categorie: { required: "Merci de choisir un type de structure" },
			siret: { required: "Merci d'écrire son siret" },
			ape: { required: "Merci d'écrire son code ape" }
		},
		
		InscriptionClientAdresseCourrier: {
			nom_societe_ad_courrier: { required: true, alphanumeric: true, messages: { required: "Merci d'écrire le nom de correspondance." } },
			rue1_ad_courrier: { required: true, alphanumeric: true, messages: { required: "Merci d'écrire une adresse." } },
			rue2_ad_courrier: { alphanumeric: true },
			rue3_ad_courrier: { alphanumeric: true },
			code_postal_ad_courrier: { required: true, messages: { required: "Merci d'écrire le code postal." } },
			ville_ad_courrier: { required: true, messages: { required: "Merci d'écrire la ville." } },
			id_pays_ad_courrier: { required: true, number: true, messages: { required: "Merci de choisir le pays." } }
		}
	};
	
	m_fRules = {
		AjoutClient: {
			nomClient: { required: true },
			codePostal: { required: true }
		},
	
		InscriptionClient: {
			nom_societe_ad: { required: true, alphanumeric: true },
			fonction_contact: { required: true },
			siret: { siret: true },
			tva_intra: { tva: true },
			ape: { ape: true },
			id_civilite_contact: { required: true },
			email_contact: { required: true, email: true, valid_email: true, different_utilisateur:$('#form-projet-inscription-client input[name="email_utilisateur"]').val() },
			telephone_contact: { required: true, telephone: true, number:true },
			nom_contact: { required: true, alphanumeric: true },
			prenom_contact: { required: true, alphanumeric: true },
			rue1_ad: { required: true, alphanumeric: true },
			rue2_ad: { alphanumeric: true },
			rue3_ad: { alphanumeric: true },
			code_postal_ad: { required: true },
			ville_ad: { required: true },
			id_pays_ad: { required: true, number: true },
			categorie: { required: true }
		}
	};

	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//
	
	constructor() {
		super(); // Appelle le constructeur de PFHBaseClass	
		
		const self = this; // Capturer le contexte de `this`

		// Configuration globale
		const $projetCommon = new ProjetCommon();
		
		// Page de choix du type de demande
		const $pageType = new  ProjetSalariePageType();
		
		// Page du détail de la demande
		const $pageDetail = new ProjetSalariePageDetail({
				typeDemande: $projetCommon.typeDemande(),
				tva: $projetCommon.tva()
			});
			
		const $pageDocs = new ProjetSalariePageDocs();
			
		this.m_item = {
			projetCommon: $projetCommon,
			pageType: $pageType,
			pageDetail: $pageDetail,
			pageDocs: $pageDocs
		};
		
		this.m_sel = {
			currentPage: `${this.sCreationProjet()} .step.active`, // Page active SELECTEUR DYNAMIQUE

			pageEtape2ClientCreation: '#projet-client-creation',
			pageEtape2ClientTrouve: '#projet-client-trouve',

			formRechercheClient: '#form-ajout-client-projet', // ETAPE 2-1 : FORMULAIRE d'ajout d'un nouveau client
			formInscriptionClient: '#form-projet-inscription-client', // ETAPE 2-2 : FORMULAIRE contenant les informations d'un nouveau client
			
			// Etape 5
			btnAfficherRecap: '[name="btn_afficher_recap"]'
		};

		$projetCommon.setDefaultLieu(this.iPageDetail().I('fdLieu').val());
		$projetCommon.setDefaultCodePostal(this.iPageDetail().I('fdCodePostal').val());
		$projetCommon.setDefaultVille(this.iPageDetail().I('fdVille').val());
		$projetCommon.setDefaultPays(this.iPageDetail().I('fdPays').find('option:selected').text());
		
		const defaultTypeDemande = $projetCommon.iCreationProjet().attr('data-type-demande');
		this.setTypeDemande(parseInt(defaultTypeDemande));
		
		//-----------------------------------------//
		//-- Initialisation des actions communes --//
		//-----------------------------------------//

		/**
		 * Gestion des click sur les éléments dynamiques
		 * @param {Event} p_event Evenement associé
		 */
		$(document).on('click', '[name="btn-change-step"]', (p_event) => {
			const $item = $(p_event.currentTarget);
			const name = $item.attr('name');

			switch (name) {
				case 'btn-change-step' :
					this.onBpNextStepClicked(p_event);
					break;
			}
		});
		
		// ACTION : La TVA à été mise à jours
		this.iCreationProjet().on('tvaUpdated', (p_event, p_tva) => {
			this.iPageDetail().setTva(p_tva);
		});

		//-------------------------------//
		//-- ETAPE 1 : TYPE DE DEMANDE --//
		//-------------------------------//

		// ACTION : Le type de demande à été mis à jours
		this.iPageType().item().on('typeDemandeUpdated', (p_event, p_data) => {
				const [p_typeDemande, p_numDemande, p_idDemande] = p_data;
				this.onTypeDemandeUpdated(p_event, p_typeDemande, p_numDemande, p_idDemande);
			});

		//-------------------------------------//
		//-- ETAPE 2-1 : LE CLIENT SELECTION --//
		//-------------------------------------//
			
		Dom.initFormValidation(
			this.I('formRechercheClient'), 
			'AppEmbauche/demandeRechercheClient', 
			this.m_fRules.AjoutClient, 
			this.m_fMsg.AjoutClient);
			
		this.I('formRechercheClient').on("submit-success", (p_event, p_result) => {
			
			// On efface le formulaire de création de client
			Dom.clearForm(this.iFormInscriptionClient(), 'idDemande');

			// Si on à un ou plusieurs clients correspondant à la recherche
			// On remplis les infos et on passe à l'étape suivante					
			if (p_result.items.length > 0) {
				var info = p_result.items[0];

				var contact = this.formatContact(
					info.contactCivilite, 
					info.contactPrenom, 
					info.contactNom,
					info.contactTelephone,
					info.contactPortable,
					info.contactEmail);

				var container = $('#projet-client-trouve');
				container.attr('data-idClient', info.id_client);
				container.attr('data-societe', info.adresseNomSociete);
				container.attr('data-codePostal', info.adresseCodePostal);
				container.find('.nom_societe span').html(info.adresseNomSociete);
				container.find('.adresse span').html([info.adresseRue1, info.adresseRue2, info.adresseRue3].filter(Boolean).join(' - '));
				container.find('.cp span').html(info.adresseCodePostal + ' / ' + [info.adresseVille, info.paysNom].filter(Boolean).join(' / '));
				container.find('.contact span').html(contact);

				// On pré-remplis le formulaire de création de client
				$(this.m_sel.formInscriptionClient + ' input[name="nom_societe_ad"]').val(info.adresseNomSociete);
				$(this.m_sel.formInscriptionClient + ' input[name="code_postal"]').val(info.adresseCodePostal);
				if (info.contactNom === null) info.contactNom = "";
				if (info.contactPrenom === null) info.contactPrenom = "";
				$(this.m_sel.formInscriptionClient + ' input[name="nom_contact"]').val(info.contactNom);
				$(this.m_sel.formInscriptionClient + ' input[name="prenom_contact"]').val(info.contactPrenom);

				this.changeStep(STEP.CLIENT_TROUVE);
			} else { // Si aucun client ne correspond à la recherche on en ajoute un nouveau
				this.afficheEtapeAjouteNouveauClient(); 
			}
		});
		
		/**
		 * Autocomplete pour la recherche de client
		 * Les input dont le nom est nomclient dans creation-projet recevera cette fonctionnalité A REVOIR RISQUE
		 */
		$(this.sCreationProjet() + ' input[name="nomClient"].autocomplete').each(function() {
				$(this).autocomplete({
					
					// Source de l'autocomplete
					source: function(p_request, p_response) {
						Ajax.postAsync (
							'AppEmbauche/autocompleteChercheClient', {
								nomClient: p_request.term
							},

							(p_result) => {
								if (p_result.items) {
									// On ajoute chaques élément du résultat à l'autocomplete
									p_response($.map(p_result.items, (p_item) => {
											return { label: p_item.adresseNomSociete, ref: p_item.id_client };
										})
									);
								}
							},

							(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
						);
					},
					
					// Action lors de la selection d'un client
					select: (p_event, p_ui) => {
						self.updateClient(p_ui.item.ref, null, STEP.DETAIL); 
					},
					
					// on effectue la recherche à partir de 3 caractères
					minLength: 3
				});
			}
		);

		//---------------------------------------------//
		//-- ETAPE 2-2 : LE CLIENT AJOUT D'UN CLIENT --//
		//---------------------------------------------//

		/** Configuration du formulaire d'inscription d'un nouveau client dans un projet */
		Dom.initFormValidation(
			this.iFormInscriptionClient(), 
			'AppClient/inscription', 
			this.m_fRules.InscriptionClient, 
			this.m_fMsgInscriptionClient);

		// Clique sur enregistrer le client lors de la saisie d'un nouveau client
		this.iFormInscriptionClient().on('submit-success', (p_event, p_result) => this.onClientAjouterSuccess(p_event, p_result));

		/** ACTION : Clique sur ajout d'un nouveau client */
		$('#btn-ajouter-nouveau-client').click(() => {
			this.afficheEtapeAjouteNouveauClient(); 
		});

		/** ACTION : Si on clique sur utilisé le client qui à été trouvé */
		$('#btn-utilise-client-trouve').click((p_event) => this.onBtnUtiliserClientTrouveClicked(p_event));

		$('#adresse-courrier-differente').change(() => {
			if ($(this).is(':checked')) {
				Dom.modifyFormRules(this.iFormInscriptionClient(), this.m_fRules.InscriptionClientAdresseCourrier, 'add');
			} else {
				Dom.modifyFormRules(this.iFormInscriptionClient(), this.m_fRules.InscriptionClientAdresseCourrier, 'remove');
			}
		});

		$('#adresse-courrier-differente').trigger('change');

		//------------------------//
		//-- ETAPE 5 : EMBAUCHE --//
		//------------------------//

		// ACTION : Un cadreInfoEmbauche à été supprimé
		this.iProjetCommon().iListingCadreInfoEmbauche().item().on('cadreInfoEmbaucheRemoved', (p_event) => this.onCommonCadreInfoEmbaucheRemoved(p_event));

		// ACTION : On à annuler l'édition d'un contrat d'embauche
		this.iProjetCommon().item().on('contratEmbaucheClosed', (p_event) => this.onCommonContratEmbaucheClosed(p_event));

		// ACTION : Une demande d'édition d'un contrat d'embauche à été effectuée
		this.iEditionContrat().item().on('contratEmbaucheEdited', (p_event, p_data) => this.onCommonContratEmbaucheEdited(p_event, p_data));

		//-----------------------------//
		//-- ETAPE 6 : RECAPITULATIF --//
		//-----------------------------//

		this.I('btnAfficherRecap').click((p_event) => this.onAfficherRecapRequested(p_event));

		//------------------------------//
		//-- SCRIPTS D'INITIALISATION --//
		//------------------------------//

		// Initialisation des flatpikers
		flatpickr('#fp_date_debut', {dateFormat: 'd/m/Y', altFormat: 'Y-m-d'});
		flatpickr('#fp_date_fin', {dateFormat: 'd/m/Y', altFormat: 'Y-m-d'});	
	}
	
	//---------------------------//
	//-- ACCESSEURS SELECTEURS --//
	//---------------------------//
	
	sCreationProjet() {
		return this.iProjetCommon().sCreationProjet();
	}
	
	//---------------------//
	//-- ACCESSEURS ITEM --//
	//---------------------//

	iCreationProjet() {
		return this.iProjetCommon().iCreationProjet();
	}

	iEditionContrat() {
		return this.iProjetCommon().iEditionContrat();
	}

	iPageDetail() {
		return this.m_item.pageDetail;
	}

	iPageType() {
		return this.m_item.pageType;
	}
	
	iProjetCommon() {
		return this.m_item.projetCommon;
	}

	iFormInscriptionClient() {
		return this.I('formInscriptionClient');
	}
	
	//-------------//
	//-- SETTERS --//
	//-------------//
	
	setTypeDemande(p_typeDemande) {
		this.iPageDetail().setTypeDemande(p_typeDemande);
		this.iProjetCommon().setTypeDemande(p_typeDemande); // On configure le tout en fonction du type de demande
	}
	
	//----------------//
	//-- FORMATTAGE --//
	//----------------//

	formatContact(p_civilite, p_prenom, p_nom, p_telephone, p_portable, p_email) {
		var contact = [p_civilite, p_prenom, p_nom].filter(Boolean).join(' ');
		if (!contact) {
			contact = 'n/d';
		} else {
			// Ajout des informations de contact si elles sont définies
			var contactDetails = [
				p_telephone ? `Tél: ${anonymiseTelephone(p_telephone)}` : null,
				p_portable ? `Portable: ${anonymiseTelephone(p_portable)}` : null,
				p_email ? `Email: ${anonymiseEmail(p_email)}` : null
			].filter(Boolean).join(', ');

			if (contactDetails) {
				contact += ` (${contactDetails})`;
			}
		}
		
		return contact;
	}

	//------------//
	//-- COMMUN --//
	//------------//

	/**
	 * Change l'étape vers l'étape dont le numéro est passé en paramètre
	 * @param {type} p_choosen
	 * @returns {undefined}
	 */
	changeStep(p_choosen) {
		//on déduit l'ID de l'étape actuelle depuis le nom de la classe
		const currentPage = this.D('currentPage');
		const currentStep = currentPage.attr('data-step');
		
		// Si on demande d'afficher la même page, on ne fait rien
		if (currentStep === p_choosen) {
			return;
		} 
		
		const nextPage = $(this.sCreationProjet() + ' .step.step' + p_choosen);
		
		this.processChangeStep(currentPage, nextPage);
	}
	
	/**
	 * Change l'étape vers l'étape suivante
	 * @returns {undefined}
	 */
	nextStep() {
		//on déduit l'ID de l'étape actuelle depuis le nom de la classe
		const currentPage = this.D('currentPage');
		const nextPage = currentPage.next();
		
		this.processChangeStep(currentPage, nextPage);
	}
	
	async processChangeStep(p_currentPage, p_nextPage) {
		const numCurrentStep = parseInt($('#' + p_currentPage[0].id).attr('data-step'));
		const numNextStep = parseInt($('#' + p_nextPage[0].id).attr('data-step'));

		try {
			// Si l'étape précédente est le détail, on lance la sauvegarde
			if (numCurrentStep === STEP.DETAIL) {
				const result =	await this.iPageDetail().saveDetail();

				if (result !== null) {
					const $projetCommon = this.iProjetCommon();
					const $listingCadreInfoEmbauches = $projetCommon.iListingCadreInfoEmbauche();

					// On met à jours les valeures par defaut de l'embauche
					$projetCommon.setDefaultLieu(this.iPageDetail().I('fdLieu').val());
					$projetCommon.setDefaultCodePostal(this.iPageDetail().I('fdCodePostal').val());
					$projetCommon.setDefaultVille(this.iPageDetail().I('fdVille').val());
					$projetCommon.setDefaultPays(this.iPageDetail().I('fdPays').find('option:selected').text());		

					// Si il y à déja des embauches, on met a jours les cadres infos
					$listingCadreInfoEmbauches.refreshAllCadreInfoEmbauche();

					// Si une embauche à été crée on l'ajoute à la liste des embauches
					$listingCadreInfoEmbauches.insertCadreInfoEmbauche(result.htmlContrat);

					// On affiche le bouton suivant
					Dom.setVisibility($(this.iProjetCommon().m_sel.boutonsChoixEtapes), true);
				}
			}
		
			// Si la prochaine étape est le récap, on le charge
			if (numNextStep === STEP.RECAP) {
				await this.majRecap();
			}
			
			// On change de page
			this.showStep(p_currentPage, p_nextPage);
		}
		catch (p_error) {
			return; 
		}
	}
	
	/**
	 * Séléctionne une étape spécifique ou bien passe à l'étape suivante
	 * @param p_currentPage Ancienne page
	 * @param p_nextPage page à afficher
	 */
	showStep(p_currentPage, p_nextPage) {
		
		// On récupère le numéro de la prochaine étape
		let numNextStep = $('#' + p_nextPage[0].id).attr('data-step');
		
		// Active les boutons de l'étapes suivante plus les précédents
		// ATTENTION : l'ordre est important du plus grand au plus petit
		switch (parseInt(numNextStep)) {
			case STEP.RECAP: 
				$('#projet-etapes button[data-step="11"]').attr('disabled', false); // Etape Récapitulatif
			case 7: 
				$('#projet-etapes button[data-step="7"]').attr('disabled', false); // Etape Embauche
			case 6:
				$('#projet-etapes button[data-step="6"]').attr('disabled', false); // Etape Annexe
			case 5:
				$('#projet-etapes button[data-step="5"]').attr('disabled', false); // Etape Détail
			case 2:
				$('#projet-etapes button[data-step="2"]').attr('disabled', false); // Etape Client
			case 1:
				$('#projet-etapes button[data-step="1"]').attr('disabled', false); // Etape Type
		}
		
		// Cache l'ancienne page et affiche la nouvelle
		p_currentPage.removeClass('active');
		p_nextPage.addClass('active');
		
		// On scroll en haut de la page
		$(window).scrollTop(0);
	}

	/**
	 * ACTION : Click sur le bouton étape suivante ou bien les étapes de projet
	 * @param p_event event
	 */
	onBpNextStepClicked(p_event) {
		p_event.preventDefault();

		// On récupère le numéro de l'étape que l'on à cliqué
		let numNextStep = $(p_event.currentTarget).attr('data-step');
		
		if (numNextStep) {
			this.changeStep(numNextStep); 
		}
		else {
			this.nextStep();
		}
	}
	
	//------------------------//
	//-- LE TYPE DE DEMANDE --//
	//------------------------//

	/**
	 * Un choix de type de demandeà été effectué
	 * @param {Event} p_event Evenement associé
	 * @param {TYPE_SOCIETE} p_typeDemande type de la demande
	 * @param {number} p_numDemande numero de la demande associée
	 * @param {number} p_idDemande id crypté de la demande associée
	 */
	onTypeDemandeUpdated (p_event, p_typeDemande, p_numDemande, p_idDemande) {
		this.setTypeDemande(p_typeDemande);
		this.iProjetCommon().setNumDemande(p_numDemande); // On modifie l'affichage avec le numero de demande
		this.iProjetCommon().setIdDemande(p_idDemande); // On modifie l'url de la page
		
		this.nextStep(); // On passe à l'étape suivante
	}

	//---------------//
	//-- LE CLIENT --//
	//---------------//
	
	/**
	 * Remplis le formulaire d'ajout d'un nouveau client avec les valeures saisie à l'étape 2
	 * Une fois effectuée on ouvre le formulaire
	 */
	afficheEtapeAjouteNouveauClient() {
		//Réinitialise les elements de formulaire contenants la donnée data-default
		Dom.defaultInput(this.iFormInscriptionClient());
		
		const $nomSociete = $(this.sCreationProjet() + ' input[name="nomClient"]');
		const $codePostal = $(this.sCreationProjet() + ' input[name="codePostal"]');
		
		// On initialise le nom de societe et le code postal du formulaire
		Dom.fillForm($(this.m_sel.pageEtape2ClientCreation), {
			nom_societe_ad: $nomSociete.val(),
			code_postal_ad: $codePostal.val()
		});
		
		// On vérifie les champs obligatoires après leurs ajour
		Dom.checkDataRequired($nomSociete);
		Dom.checkDataRequired($codePostal);
		
		this.changeStep(STEP.CLIENT_INSCRIPTION);
	}
	
	/**
	 * ATTENTION ASYNCHRONE
	 * Met a jours la demande avec le client dont l'ID est passé en paramètre
	 * La TVA est également modifiée selon le client
	 * Si tout se passe bien, execute le callback passé en paramètre
	 * @param {type} p_idClient
	 * @param {type} p_callBackOnSuccess callback appelé en cas de succès
	 * @param {type} p_nextStepOnSuccess étape de redirection en cas de succès
	 * @returns {undefined}
	 */
	updateClient(p_idClient, p_callBackOnSuccess, p_nextStepOnSuccess) {
		// Paramètres par défaut (compatible IE)
		if (p_nextStepOnSuccess === undefined) p_nextStepOnSuccess = null;

		$.blockUI();		
		Ajax.postAsync (
			'AppEmbauche/demandeMajClient', {
				idClient: p_idClient
			},
			
			(p_result) => {
				// La mise à jours d'un client peut entrainer la modification de la TVA
				this.iProjetCommon().setTva(p_result.tva);
				
				if (p_result.lieuParDefaut !== '') {
					var lieuItem = this.iPageDetail().I('fdLieu');
					if (lieuItem.val() === '') {
						lieuItem.val(p_result.lieuParDefaut);
					}
				}
			
				if (p_result.codePostalParDefaut !== '') {
					var codePostalItem = this.iPageDetail().I('fdCodePostal');
					if (codePostalItem.val() === '') {
						codePostalItem.val(p_result.codePostalParDefaut);
					}
				}
				
				if (p_result.villeParDefaut !== '') {
					var villeItem = this.iPageDetail().I('fdVille');
					if (villeItem.val() === '') {
						villeItem.val(p_result.villeParDefaut);
					}
				}
				
				if (p_result.budgetHTParDefaut > 0) {
					var htItem = this.iPageDetail().I('fdPrixHT');
					if (parseFloat(htItem.val()) === 0) {
						htItem.val(p_result.budgetHTParDefaut);
						htItem.trigger('change');
					}
				}
				
				if (p_result.budgetTTCParDefaut > 0) {
					var ttcItem = this.iPageDetail().I('fdPrixTTC');
					if (parseFloat(ttcItem.val()) === 0) {
						ttcItem.val(p_result.budgetTTCParDefaut);
						ttcItem.trigger('change');
					}
				}
				
				if (p_callBackOnSuccess !== null) {
					p_callBackOnSuccess(p_result);
				}
				
				if (p_nextStepOnSuccess !== null) {
					this.changeStep(p_nextStepOnSuccess);
				}
				
				$.unblockUI();
			},
			
			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI)
		);	
	}

	//--------------//
	//-- EMBAUCHE --//
	//--------------//

	/**
	 * Modifie l'affichage en fonction du mode de page d'embauche.
	 * La page embauche peut être en mode Edition de contrat ou bien en mode recherche de salarié
	 * @param {EMBAUCHE_MODE} p_pageMode mode souhaité
	 */
	embaucheSetPageMode(p_pageMode) {
		
		let $projetCommon = this.iProjetCommon();
		let $editionContrat = this.iEditionContrat();
		
		let rechercheSalarie = $projetCommon.m_item.rechercheSalarie;
		
		// ATTENTION : On affiche le formulaire dans tous les cas ??? bizare
		Dom.setVisibility($($editionContrat.m_sel.formEditionEmbauche), true);
		
		switch (p_pageMode) {
			case EMBAUCHE_MODE.RECHERCHE:				
				// On cache l'édition du contrat
				Dom.setVisibility($editionContrat.item(), false); 
				
				// On affiche la recherche
				rechercheSalarie.setVisibile(true); 
				rechercheSalarie.setAideVisibile(true);
				
				var nbEmbauches = $projetCommon.iListingCadreInfoEmbauche().embaucheCount();
				
				$('#projet-etapes button[data-step="11"]').prop('disabled', nbEmbauches===0); // on active ou non l'étape 11 selon si on à des contrats
			
				const hasEmbauche = (nbEmbauches > 0);
				Dom.setVisibility($projetCommon.iCadreListingEmbauches(), hasEmbauche); // On affiche ou non le listing d'embauche	
				Dom.setVisibility($($projetCommon.m_sel.boutonsChoixEtapes), hasEmbauche); // On affiche ou non les boutons suivant ou précédent
				
				break;

			case EMBAUCHE_MODE.EDITION_CONTRAT:
				// On affiche l'édition du contrat
				Dom.setVisibility($editionContrat.item(), true);
				
				// On cache la recherche
				rechercheSalarie.setVisibile(false);
				rechercheSalarie.setAideVisibile(false);
				
				Dom.setVisibility($projetCommon.iCadreListingEmbauches(), false); // On cache le listing d'embauche
				Dom.setVisibility($($projetCommon.m_sel.boutonsChoixEtapes), false); // On cache les boutons suivant ou précédent
				break;
		}
	}

	onCommonContratEmbaucheEdited(p_event, p_data) {
		p_event.preventDefault();

		// Après avoir séléctionné un salarié on efface la recherche
		this.iProjetCommon().iRechercheSalarie().setResultVisibile(false);
				
		// Affichage de la page
		this.embaucheSetPageMode(EMBAUCHE_MODE.EDITION_CONTRAT);
	}
	
	//-----------//
	//-- RECAP --//
	//-----------//
	
	majRecap() {
		return new Promise((resolve, reject) => {
			$.blockUI();
			Ajax.postAsync(

				'AppEmbauche/getTplRecapDemande', {},

				(p_result) => {
					$.unblockUI();

					// On met à jours le recap
					$('#projet-recap').html(p_result.html);	
					resolve();
				},

				(p_result) => { 
					Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI);
					reject(p_result);
				}
			);
		});
	}
	
	//------------//
	//-- EVENTS --//
	//------------//

	/**
	 * ACTION : L'ajout d'un nouveau client est un succes
	 * On met a jours le client
	 * @param {type} p_event
	 * @param {type} p_result
	 */
	onClientAjouterSuccess(p_event, p_result) {
		const successCallback = () => {
			$(this.sCreationProjet() + ' [name="nomClient"]').val(p_result.nomClient); // On remplace tout ce qui contient nomClient (MERDIQUE REVOIR DOM)
			$(this.sCreationProjet() + ' [name="codePostal"]').val(p_result.codePostal); // On remplace dtout ce qui contient codePostal (MERDIQUE REVOIR DOM)
		};

		// Une fois le client créer on l'associe à la demande
		// ATTENTION ASYNCHRONE
		this.updateClient(p_result.idClient, successCallback, STEP.DETAIL);
	}
	
	/** ACTION : On clique sur utiliser le client trouvé */
	onBtnUtiliserClientTrouveClicked() {
		var container = $(this.sCreationProjet() + ' ' + this.m_sel.pageEtape2ClientTrouve);

		const successCallback = () => {
			$(this.sCreationProjet() + ' [name="nomClient"]').val(container.attr('data-societe'));
			$(this.sCreationProjet() + ' [name="codePostal"]').val(container.attr('data-codePostal'));
		};

		// ATTENTION ASYNCHRONE
		this.updateClient(container.attr('data-idClient'), successCallback, STEP.DETAIL);
	}
	
	onCommonContratEmbaucheClosed() {
		// On efface la recherche avant de revenir à la page de recherche
		//$(rechercheSalarie.sel.rechercheSalarieTxt).val(''); 
		this.iProjetCommon().iRechercheSalarie().setValue('');
		this.embaucheSetPageMode(EMBAUCHE_MODE.RECHERCHE);
	}
	
	onCommonCadreInfoEmbaucheRemoved() {
		// On rafraichis la page
		this.embaucheSetPageMode(EMBAUCHE_MODE.RECHERCHE);
	}
	
	onAfficherRecapRequested(p_event) {
		// On désactive par défaut
		p_event.preventDefault();
		
		this.changeStep(STEP.RECAP);
	}
};

//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------- DOCUMENT READY -------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//

$(document).ready(() => {	
	const pageCourante = Dom.getPageName();
	
	// Si on est pas sur la page de projet salarié on ne fait rien
	if (pageCourante !== "salarie-projet") {
		return;
	}
	
	// Initialisation du projet
	new ProjetSalarie();
});