"use strict";

import { Dom } from './dom.js';
import { Debug } from './debug.js';

//-------------------//
//-- TYPES COMMUNS --//
//-------------------//

window.TYPE_SOCIETE = {
	NON_DEFINI: 0,
	PROD_FH: 1,
	EXTERNALISATION : 2,
	AUDIOVISUAL : 3
};

window.CATEGORIE_SALARIALE = {
	NON_DEFINI: 0,
	ARTISTE: 1,
	TECHNICIEN: 2,
	ENSEIGNEMENT: 4,
	PERMANENT: 3
};

//--------------//
//-- CHECHERS --//
//--------------//

/**
 * Vérifie si le code postal passé en paramètre est cohérent
 * @param {type} p_codePostal
 * @returns {Boolean}
 */
function checkCodePostal(p_codePostal) {
	// Vérifie si le code postal a exactement 5 caractères
	if (p_codePostal.length !== 5) {
		return false;
	}

	// Expression régulière qui vérifie la validitée d'un code postal.
	// Il doit contenir 5 chiffres ou bien la lettre A ou B si on est en corse
	const regex = /^(\d{5}|2[ABab]\d{3})$/;
	return regex.test(p_codePostal);
}

function checkEmail(p_email) {
	// Vérification de la validité de l'email avec une expression régulière
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	return emailRegex.test(p_email);
}

/**
 * Ajout d'une validation dans JQuery Pour vérifier les IBAN
 * @param {String} p_iban
 */
function checkIban(p_iban) {
	const ibanValidationModulo = 97;
	if (p_iban.length === 0) {
		return true;
	}

	let modifiedIban = p_iban.toUpperCase(); // On force les caractères alphabétiques en majuscule
	modifiedIban = modifiedIban.replace(new RegExp(" ", "g"), ""); // on supprime les espaces

	// le code iban doit faire plus de 4 caractères
	if (modifiedIban.length < 5) { 
		return false;	
	}

	// puis on transfert les quatre premiers caractères en fin de chaine.
	modifiedIban = modifiedIban.substring(4, modifiedIban.length)+modifiedIban.substr(0,4);

	// On convertit les caractères alphabétiques en valeur numérique
	let numericIbanString = "";
	for (let index = 0; index < modifiedIban.length; index ++) {
		let currentChar = modifiedIban.charAt(index);
		let currentCharCode = modifiedIban.charCodeAt(index);

		if ((currentCharCode > 47) && (currentCharCode <  58)) {
			
			// si le caractère est un digit, on le recopie			
			numericIbanString = numericIbanString + currentChar;
		} else if ((currentCharCode > 64) && (currentCharCode < 91)) {
			
			// si le caractère est une lettre, on le converti en valeur			
			let tmp = currentCharCode-65+10;
			numericIbanString = numericIbanString + tmp;
		} else {
			
			// sinon, le code iban est invalide (caractère invalide).
			return false; 
		}
	}

	// On a maintenant le code iban converti en un nombre. Un très gros nombre.
	// Tellement gros que javascript ne peut pas le gérer.
	// Pour calculer le modulo, il faut donc y aller par étapes :
	// on découpe le nombre en blocs de 5 chiffres.
	// Pour chaque bloc, on préfixe avec le modulo du bloc précédent (2 chiffres max,
	// ce qui nous fait un nombre de 7 chiffres max, gérable par javascript).
	let previousModulo = 0;
	for (let index = 0; index < numericIbanString.length; index += 5) {
		let subpart = previousModulo+""+numericIbanString.substr(index, 5);
		previousModulo = subpart % ibanValidationModulo;
	}

	return previousModulo === 1;
}

function checkNumCS(p_numCS) {
	let controle;
	let nombre;
	let index_controle;
	let tableau_controle = [" ", "A", "B", "C", "D", "E", "F", "G", "H", "J", "K", "L", "M", "N", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y"];
	
	if (p_numCS.length > 8 || p_numCS.length < 7) { 
		return false; 
	} 
	
	controle = p_numCS.substr(0, 1); //on récupère la lettre de contrôle devant
	nombre = p_numCS.substr(1, p_numCS.length - 1); //on récupère le nombre
	for (let i = 0; i < tableau_controle.length; i++) {
		if (tableau_controle[i] === controle) {
			index_controle = i;
		}
	}
	return ((nombre % 23) + 1) === index_controle;
}

function checkNumSecu(p_numSecu) {
	let retKey = '';

	// Pour la corse on remplace 2A et 2B
	p_numSecu = p_numSecu.replace("2A", "19").replace("2B", "18");

	// Cas spécifique pour les étrangers qui n'ont pas de numéro de sécu
	if (p_numSecu.length === 7 && p_numSecu.substring(5, 7) === "99") {
		return true;
	}

	if (p_numSecu.length >= 13) {
		retKey = (97 - (parseInt(p_numSecu.substring(0, 13), 10) % 97)).toString();
		if (retKey.length === 1) {
			retKey = '0' + retKey;
		}

		if (p_numSecu.length === 15 && retKey === p_numSecu.substring(13, 15)) {
			return true;
		}
	}

	return false;
}

function checkSiret(p_siret) {
	// Le siret est optionnel
	if (p_siret.length === 0) {
		return true;
	}
	
	// Le siret est nombre de 14 chiffres
	if (!$.isNumeric(p_siret) || p_siret.length !== 14) {
		return false;
	}

	// On récupère le SIREN (9 premiers chiffres)
	let siren = p_siret.substr(0, 9);
	
	let somme = 0;
	let tmp;
	for (let cpt = 0; cpt < siren.length; cpt++) {
		if ((cpt % 2) === 1) { // Les positions paires : 2ème, 4ème, 6ème et 8ème chiffre
			tmp = siren.charAt(cpt) * 2; // On le multiplie par 2
			if (tmp > 9) tmp -= 9;	// Si le résultat est supérieur à 9, on lui soustrait 9
		} else {
			tmp = siren.charAt(cpt);
		}
		
		somme += parseInt(tmp);
	}
	
	// Si la somme est un multiple de 10 alors le SIREN est valide	
	if ((somme % 10) === 0) {
		return true;
	}	
	
	return false;
}

function checkTelephone(p_telephone) {
	// Vérification de la validité d'un numéro de téléphone
	// Accepte les numéros de 10 chiffres, avec ou sans espaces, tirets ou parenthèses
	const phoneRegex = /^\+?[0-9\s\-().]{10,15}$/;
	return phoneRegex.test(p_telephone);
}

function isNaN(p_x) {
  return p_x !== p_x;
}
window.isNaN = isNaN;

//------------------//
//-- ANONYMISEURS --//
//------------------//

function anonymiseEmail(p_email, charsToKeep = 3) {
	// Si l'email n'est pas valide, on le retourne sans le changer
	if (!checkEmail(p_email)) {
		return '';
	}

	// Découpage de l'email en deux parties : local (avant @) et domaine (après @)
	const [localPart, domainPart] = p_email.split("@");

	// Limitation des caractères conservés au maximum à la longueur du localPart
	const charsToKeepSafe = Math.min(charsToKeep, localPart.length);
		
	// Anonymisation : on garde les premiers `charsToKeepSafe` caractères,
	// on remplace le reste (sauf le dernier) par des 'x', et on garde le dernier caractère
	const anonymisedLocal =
		localPart.slice(0, charsToKeepSafe) +
		"*".repeat(localPart.length - charsToKeepSafe - 1) +
		localPart.slice(-1);

	// On retourne l'email anonymisé
	return `${anonymisedLocal}@${domainPart}`;
}
window.anonymiseEmail = anonymiseEmail;

function anonymiseTelephone(p_telephone) {
	// Vérification de la validité du numéro de téléphone
	if (!checkTelephone(p_telephone)) {
		return '';
	}

	// Suppression des espaces, tirets, et parenthèses pour standardiser le format
	const cleanedTelephone = p_telephone.replace(/[\s\-().]/g, '');

	// On anonymise : conserver les 2 premiers et 2 derniers chiffres, remplacer le reste par des 'x'
	const anonymisedTelephone =
		cleanedTelephone.slice(0, 2) +
		"*".repeat(cleanedTelephone.length - 4) +
		cleanedTelephone.slice(-2);

	return anonymisedTelephone;
}
window.anonymiseTelephone = anonymiseTelephone;

//------------//
//-- JQUERY --//
//------------//

/**
 * Surcharge de fonctions JQuery
 * @param {type} $
 * @returns {undefined}
 */
(function($) {
	var originalVal = $.fn.val;

	// Surcharger la méthode jQuery .val()
	$.fn.val = function(value) {
		
		// Si une valeur est fournie, c'est une écriture
		if (typeof value !== 'undefined') {
			// Appelle la méthode originale .val() pour définir la valeur
			const result = originalVal.call(this, value); 

			// Déclenche checkDataRequired après la modification
			this.each(function() {
				const $this = $(this);
				// Vérifie si l'élément a data-required="1"
				if ($this.attr('data-required') === "1") { 
					Dom.checkDataRequired($this);
				}
			});
			
			return result;			
		} else {
			// Si aucune valeur n'est fournie, c'est une lecture
			
			// Appelle la méthode originale .val()
			return originalVal.call(this);
		}
	};
})(jQuery);

//-----------------------//
//-- JQUERY VALIDATORS --//
//-----------------------//
	
// validator method :
// value : String valeure à tester
// element : Element JQuery à valide
// params : Onject
	
$.validator.addMethod("alphanumeric", function (value, element) {
	return this.optional(element) || /^[a-z0-9ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ_-\s']+$/i.test(value);
}, "Valeur alphanumérique uniquement (accents non autorisés)");
		
$.validator.addMethod("ape", function (value, element) {
	return this.optional(element) || /^[0-9]{4} ?[a-zA-Z]+$/i.test(value);
}, "Merci de renseigner un code APE valide");
		
$.validator.addMethod("dateBR", function (value, element) {
	let is_valid;
	let dateEN;
	let dateFR;
	
	is_valid = false;
	if (value.indexOf("-") >= 0) {
		dateEN = value.split('-');
		dateFR = dateEN[2] + "/" + dateEN[1] + "/" + dateEN[0]; // IL FAUT AJOUTER LES / ENTRE LES CHAMPS
	} else {
		dateFR = value;
	}
	
	is_valid = dateFR.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/);
	let dateArr = dateFR.split('/');
	let newDate = new Date(dateArr[2], parseInt(dateArr[1], 10) - 1, parseInt(dateArr[0], 10));
	is_valid = newDate.getMonth() === parseInt(dateArr[1], 10) - 1;
	is_valid = dateArr[2] >= "1920";
	return is_valid; // GROS BUG AVEC IS VALID.. IL EST ASSIGNE 3 FOIS SANS TESTS A REVOIR
}, "Merci d'écrire une date valide au format JJ/MM/AAAA");
		
$.validator.addMethod("different_utilisateur", function (value, element, mail_utilisateur) {
	return value !== mail_utilisateur;
}, "Merci de renseigner un email différent du vôtre");

$.validator.addMethod("iban", checkIban, "Merci d'écrire un iban valide");

$.validator.addMethod("num_secu", checkNumSecu, "Merci de renseigner un numéro de sécurité social valide");

$.validator.addMethod("num_cs", checkNumCS, "Merci de renseigner un numéro de congés spectacle valide");
	
$.validator.addMethod("siret", checkSiret, "Merci de renseigner un numéro siret valide");

//----------------------------------//
//-- JQUERY VALIDATORS FORMATAGES --//
//----------------------------------//

$.validator.addMethod(
	"strtolower", 
	function (p_value) {
		return p_value.toLowerCase(); 
	},
	"Minuscules obligatoires");

$.validator.addMethod(
	"strtoupper", 
	function (p_value) {
		return p_value.toUpperCase(); 
	}, 
	"Majuscules obligatoires");

/**
 * Ajout d'une validation dans JQuery Pour vérifier un numéro de téléphone
 * @param {} p_value
 * @param {} p_element
 */
$.validator.addMethod(
	"telephone", 
	function (p_value, p_element) {
		if (this.optional(p_element)) { 
			return true;
		}

		if (p_value.length<8 || p_value.trim() === '' || p_value.match(/^(\d)\1*$/)) {
			return false;		
		}

		return true;
	},
	"Merci de renseigner un numéro de téléphone valide");

/**
 * Ajout d'une validation dans JQuery Pour vérifier une TVA
 * @param {} value
 */
$.validator.addMethod(
	"tva", 
	function (value) {
		if (value.length > 0) {
			return checkVATNumber(value);
		}
		return true;
	},
	"Merci de renseigner un numéro TVA valide");

/**
 * Ajouter une méthode de validation pour vérifier si au moins une des checkboxes est cochée
 * @param p_value valeur de l'élément à valider
 * @param p_element élément à valider
 * @param p_params paramètre fournis lors de la déclaration de la rêgle
 */
$.validator.addMethod(
	"atLeastOneChecked",
	function(p_value, p_element, p_params) {
		// Vérifier combien de checkboxes sont cochées
		var checked = $(p_params).filter(":checked").length;
		return checked > 0;
	},
	"Vous devez cocher au moins une des cases.");

/**
 * Ajout d'une validation dans JQuery Pour vérifier un code postal
 * Si le champ contenant le code postal est associé à un pays, ce dernier doit définit la donnée data-assoc-select-pays-id="id de l'item contenant l'id pays en valeur"
 * @param p_value valeur de l'élément à valider
 * @param p_element élément à valider
 */
$.validator.addMethod(
	"checkValidCodePostal",
	function (p_value, p_element) {
		const idFrance = 1;
		let selectedIdPays = idFrance; // ID du pays pour la France

		// On regarde si l'élément est associé à un autre élément contenant l'Id du pays
		// On récupère l'ID du pays actuelle
		const inputPaysId = $(p_element).attr('data-assoc-select-pays-id'); // Id du select contenant le pays actuel
		if (inputPaysId) {
			selectedIdPays = parseInt($(`#${inputPaysId}`).val(), 10);
		}

		// Vérifiez si le pays est la France avant d'appliquer les restrictions
		if (selectedIdPays === idFrance) {
			return checkCodePostal(p_value);
		}
		
		return true;
	},
	"Merci de renseigner un code postal valide");
	
$.validator.addMethod(
	"valid_email", 
	function (value) {
		let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		return regex.test(value);
	},
	"Merci de renseigner un email valide");

/**
 * Ajout d'une validation dans JQuery Pour vérifier un nombre après l'avoir nettoyé
 * La valeur de l'élément est modifiée avant sa validation
 * @param p_value valeur de l'élément à valider
 * @param p_element élément à valider
 */
$.validator.addMethod(
	"trimmedNumber", 
	function (p_value, p_element) {
		let newValue = $.trim(p_value);
		$(p_element).val(newValue);
		return $.validator.methods.number.call(this, newValue, p_element);
	},
	$.validator.messages.number);

/**
 * Ajout d'une validation dans JQuery qui trim un champ avant toute validation
 * La valeur de l'élément est modifiée avant sa validation
 * @param p_value valeur de l'élément à valider
 * @param p_element élément à valider
 */
$.validator.addMethod(
	"trimmedRequired", 
	function (p_value, p_element) {
		let newValue = $.trim(p_value);
		$(p_element).val(newValue);
		return $.validator.methods.required.call(this, newValue, p_element);
	},
	$.validator.messages.required);

//------------//
//-- POPINS --//
//------------//

function popin(div) {
	document.getElementById(div).style.display = 'block';
	return false;
}
window.popin = popin;

function ferme_popin(div) {
	document.getElementById(div).style.display = 'none';
	return false;
}
window.ferme_popin = ferme_popin;

//----------------//
//-- FORMATTAGE --//
//----------------//

/**
 * remplace les virgules contenue par la caractéristique value par un .
 * @param {type} p_obj objet possédant une caractéristique value
 */
function noVirgule(p_obj) {
	p_obj.value=p_obj.value.replace(/,/g,'.');
}
window.noVirgule = noVirgule;

