"use strict";

import { Ajax } from './ajax.js';
import { Dom } from './dom.js';
import { Debug } from './debug.js';
import { PFHBaseClass } from './pfhBaseClass.js';
import { ItemCatalogue } from './itemCatalogue.js';


export class PageCatalogue extends PFHBaseClass {
	//---------------------//
	//-- DONNEES MEMBRES --//
	//---------------------//
	
	m_loading = false;
	m_nbResultsPending = 0;
	m_itemCatalogue = null;
	
	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//
	
	constructor() {
		super(); // Appelle le constructeur de PFHBaseClass	
		
		this.m_sel = {
			catalogueFltrDesignation: '#fltr-catalogue-designation',
			catalogueFltrLstDepartements: '#fltr-catalogue-departements',
			catalogueFltrEnvironnements: '#fltr-catalogue-environnements',
			catalogueFltrLstThemes: '#fltr-catalogue-themes',
			catalogueFltrPublic: '#fltr-catalogue-public',
			catalogueFltrTypePresta: '#fltr-catalogue-type-presta',
			btnRechercheCatalogue: '#catalogue-rechercher',
			catalogueResults: '#catalogue-results'
		};
		
		/// On clique sur le bouton rechercher
		this.I('btnRechercheCatalogue').click((p_event) => this.onBtnRechercheCatalogueClicked(p_event));
		
		// On scroll le catalogue
		//this.I('catalogueResults').on('scroll', () => this.onCatalogueScroll());
		$(window).on('scroll', () => this.onCatalogueScroll());
		
		this.I('catalogueFltrLstDepartements').select2({
			placeholder: "Filtrer par département",
			closeOnSelect: false // Permet de rester ouvert après chaque sélection
		});
		
		this.I('catalogueFltrLstThemes').select2({
			placeholder: "ou par thème",
			closeOnSelect: false // Permet de rester ouvert après chaque sélection
		});
		
		this.I('catalogueFltrEnvironnements').select2({
			placeholder: "Environnement",
			closeOnSelect: false // Permet de rester ouvert après chaque sélection
		});
		
		this.I('catalogueFltrPublic').select2({
			placeholder: "Type de public",
			closeOnSelect: false // Permet de rester ouvert après chaque sélection
		});
		
		this.m_itemCatalogue = new ItemCatalogue();
		
		/**
		 * Gestion des click sur les éléments dynamiques
		 * @param {Event} p_event Evenement associé
		 */
		$(document).on('click', '[name="btn-show-detail-presta"]', (p_event) => {
			const $item = $(p_event.currentTarget);
			const name = $item.attr('name');

			this.m_itemCatalogue.onDynamicItemClicked(name, p_event);
		});
		
		this.I('btnRechercheCatalogue').trigger('click');
	}
	
	//----------------//
	//-- ACCESSEURS --//
	//----------------//
	
	nbResultsPending() {
		return this.m_nbResultsPending;
	}
	
	//-------------//
	//-- SETTERS --//
	//-------------//
	
	setNbResultsPending(p_nbResultsPending) {
		this.m_nbResultsPending = parseInt(p_nbResultsPending);
	}
	
	//----------------//
	//-- EVENEMENTS --//
	//----------------//
	
	onCatalogueScroll() {
		if (this.nbResultsPending() === 0) {
			return;
		}
			
		// On récupère la position verticale actuelle de la fenêtre
		const scrollPosition = $(window).scrollTop();
		// Hauteur de la fenêtre (viewport)
		const windowHeight = $(window).height();
		// Hauteur totale du document
		const documentHeight = $(document).height();

		// Vérifier si on est proche du bas (on peut ajouter une tolérance de 10px par exemple)
		if (scrollPosition + windowHeight >= documentHeight - 10) {
			this.loadMoreResults();
		}
	}
	
	onBtnRechercheCatalogueClicked(p_event) {
		p_event.preventDefault();
		
		this.m_loading = true; // Bloque les nouvelles requêtes pendant le chargement

		// On lance la recherche de catalogue
		Ajax.postAsync(
			'AppPrestation/rechercheCatalogue', {
				newSearch: '1',
				fltrDesignation: this.I('catalogueFltrDesignation').val(),
				fltrLstDepartements: this.I('catalogueFltrLstDepartements').val(),
				fltrEnvironnement: this.I('catalogueFltrEnvironnements').val(),
				fltrLstThemes: this.I('catalogueFltrLstThemes').val(),
				fltrPublic: this.I('catalogueFltrPublic').val(),
				fltrTypePresta: this.I('catalogueFltrTypePresta').val()
			},
		
			(p_result) => {
				Ajax.defaultAsyncJSONSuccessCallback(p_result);
				
				const catalogueResults = this.I('catalogueResults');
				
				// On effectue une nouvelle recherche, on efface le contenu de la précédete recherche
				catalogueResults.empty();
				
				this.setNbResultsPending(p_result.nbResultsPending);
				
				p_result.lstPreview.forEach(p_item => {
					const $item = $(p_item);
					catalogueResults.append($item);
				});
				
				this.m_loading=false;
			},
			
			(p_result) => {
				Ajax.defaultAsyncJSONErrorCallback(p_result);
				this.m_loading=false;
			}
		);
	}
	
	//------------//
	//-- DIVERS --//
	//------------//
	
	loadMoreResults() {
		this.m_loading = true; // Bloque les nouvelles requêtes pendant le chargement

		Ajax.postAsync(
			'AppPrestation/rechercheCataloguePlus',
				
			{},
		
			(p_result) => {
				Ajax.defaultAsyncJSONSuccessCallback(p_result);
				
				this.setNbResultsPending(p_result.nbResultsPending);
				
				p_result.lstPreview.forEach(p_item => {
					const $item = $(p_item);
					this.I('catalogueResults').append($item);
				});
				
				this.m_loading=false;
			},
			
			(p_result) => {
				Ajax.defaultAsyncJSONErrorCallback(p_result);
				this.m_loading=false;
			}
		);
	}
}
